const DescriptionCard = ({ heading, subhead, loading = false }) => {
    return (
        <div
            className="py-6 px-10 border border-[#d9d9d9] dark:border-[#424E59] rounded-lg text-center flex flex-col items-center"
            style={{ boxShadow: "0px 1px 2px 0px #1018280D" }}
        >
            {loading ? (
                // Loading State (Skeleton UI)
                <>
                    <div className="h-[14px] w-[120px] rounded-[3px] bg-[#F0F0F0] animate-pulse"></div>
                    <div className="mt-4 h-[32px] w-[120px] rounded-[3px] bg-[#F0F0F0] animate-pulse"></div>
                </>
            ) : (
                // Normal State
                <>
                    <p className="text-sm font-medium text-[#4B5563] mb-4 dark:text-white">
                        {heading}
                    </p>
                    <p className="text-xl font-medium text-[#262626] dark:text-white">
                        {subhead}
                    </p>
                </>
            )}
        </div>
    );
};

export default DescriptionCard;
