import { Bar } from "react-chartjs-2";
import {
    Chart as ChartJS,
    BarElement,
    CategoryScale,
    LinearScale,
    Title,
    Tooltip,
    Legend,
} from "chart.js";
import { useIsDarkTheme } from "helpers/darktheme";

ChartJS.register(
    BarElement,
    CategoryScale,
    LinearScale,
    Title,
    Tooltip,
    Legend
);

const BarChartView = ({ datas, date, title }) => {
    const isDarkTheme = useIsDarkTheme();

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: "bottom",
                labels: {
                    color: isDarkTheme ? "#334155" : "#FFFFFF",
                },
            },
        },
        scales: {
            x: {
                ticks: {
                    color: isDarkTheme ? "#334155" : "#FFFFFF",
                },
                grid: {
                    display: false,
                },
            },
            y: {
                ticks: {
                    color: isDarkTheme ? "#334155" : "#FFFFFF",
                },
                grid: {
                    color: isDarkTheme ? "#e2e8f0" : "#424E59",
                },
            },
        },
    };

    return (
        <>
            <div className="border border-[#D9D9D9] shadow rounded-lg dark:border-[#424E59]">
                <div className="px-6 py-5 flex justify-between items-center border-b border-[#D9D9D9] dark:border-[#424E59]">
                    <div className="font-medium">
                        <p className="text-[#262626] dark:text-white leading-6 text-[16px]">
                            {title}
                        </p>
                        <p className="text-[#8C8C8C] dark:text-white text-[14px] leading-4 ">{`Project data last fetched ${date} ago`}</p>
                    </div>
                </div>
                <div className="overflow-x-auto w-full px-8 pt-10 pb-6">
                    {Object.keys(datas).length > 0 && (
                        <Bar data={datas} options={options} />
                    )}
                </div>
            </div>
        </>
    );
};

export default BarChartView;
