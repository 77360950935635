export const cloudCounterTabs = [
    {
        id: "software",
        title: "Jira Software",
    },
    {
        id: "scheme",
        title: "Jira Scheme",
    },
    {
        id: "management",
        title: "Jira Management",
    },
];
