// store/reducers/projectReducer.js
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import FileSaver from "file-saver";

const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
};

const onToggleDisplay =
    (actionCreator, options = []) =>
    (dispatch) => {
        setTimeout(() => dispatch(actionCreator(...options)), 7000);
    };

const initialState = {
    loading: false,
    successMsg: "",
    errMsg: "",
    cloning: false,
    cloneData: "",
    isVisualizing: false,
    visualizeData: {},
    projects: [],
    releases: [],
    filters: [],
    currentProject: {},
    currentRelease: {},
    currentFilter: {},
    dashboardData: {},
    isSelected: false,
    modalState: false,
    bodyToken: "",
    type: "projects",
    projectsLoading: false,
    currentDashboardScreen: "",
    hideOverlay: false, // false means overlay is visible
    theme: localStorage.getItem("theme") || "light", // 🔥 Add Theme State
};

const projectSlice = createSlice({
    name: "project",
    initialState,
    reducers: {
        setHideOverlay: (state, action) => {
            state.hideOverlay = action.payload;
            // Optionally, persist this value:
            localStorage.setItem(
                "hideOverlay",
                action.payload ? "true" : "false"
            );
        },
        fetchCSVStart: (state) => {
            state.loading = true;
        },
        fetchCSVSuccess: (state) => {
            state.successMsg = "Successfully generated CSV";
            state.loading = false;
        },
        fetchCSVFail: (state, action) => {
            state.errMsg = action.payload;
            state.loading = false;
        },
        clearError: (state) => {
            state.errMsg = "";
        },
        clearSuccess: (state) => {
            state.successMsg = "";
        },
        cloneSuccess: (state, action) => {
            state.cloneData = action.payload;
            state.cloning = false;
        },
        setIsVisualizing: (state, action) => {
            state.isVisualizing = action.payload;
        },
        setVisualizeData: (state, action) => {
            state.visualizeData = action.payload;
        },
        saveProjects: (state, action) => {
            state.projects = action.payload;
        },
        setCurrentProject: (state, action) => {
            state.currentProject = action.payload;
        },
        setReleases: (state, action) => {
            state.releases = action.payload;
        },
        setFilters: (state, action) => {
            state.filters = action.payload;
        },
        setCurrentRelease: (state, action) => {
            state.currentRelease = action.payload;
        },
        setCurrentFilter: (state, action) => {
            state.currentFilter = action.payload;
        },
        setDashboardData: (state, action) => {
            state.dashboardData = action.payload;
        },
        toggleModal: (state, action) => {
            state.modalState = action.payload;
        },
        projectsLoading: (state, action) => {
            state.projectsLoading = action.payload;
        },
        setIsSelected: (state) => {
            state.isSelected = true;
        },
        setBodyToken: (state, action) => {
            console.log(action.payload);
            state.bodyToken = action.payload;
        },
        setSidebarType: (state, action) => {
            state.type = action.payload;
        },
        setCurrentDashboardScreen: (state, action) => {
            state.currentDashboardScreen = action.payload;
        },
        setTheme: (state, action) => {
            state.theme = action.payload;
            localStorage.setItem("theme", action.payload);
        },
    },
});

export const onFetchCSV = createAsyncThunk(
    "project/fetchCSV",
    async (reqData, { dispatch, rejectWithValue }) => {
        try {
            const { data } = await axios.post("/csv", reqData);
            const blob = b64toBlob(data, "application/zip");
            FileSaver.saveAs(blob, `project__${reqData.project_key}.zip`);
            dispatch(onToggleDisplay(clearSuccess));
            return; // No payload needed for success in this case
        } catch (err) {
            let message = "";
            const status = err.response?.status || 500;
            switch (status) {
                case 404:
                    message = "There is no such project with the ID provided";
                    break;
                case 401:
                    message = "Your token is Invalid";
                    break;
                case 400:
                    message = "Your request required a token and a project ID";
                    break;
                default:
                    message = "Some error occurred, Please try again";
                    break;
            }
            dispatch(onToggleDisplay(clearError));
            return rejectWithValue(message); // Handle failure
        }
    }
);

export const {
    fetchCSVStart,
    fetchCSVSuccess,
    fetchCSVFail,
    clearError,
    clearSuccess,
    cloneSuccess,
    saveProjects,
    setReleases,
    setFilters,
    setCurrentProject,
    setCurrentRelease,
    setCurrentFilter,
    setDashboardData,
    toggleModal,
    projectsLoading,
    setIsSelected,
    setBodyToken,
    setSidebarType,
    setCurrentDashboardScreen,
    setVisualizeData,
    setIsVisualizing,
    setTheme, // 🔥 Export setTheme
    setHideOverlay,
} = projectSlice.actions;

export default projectSlice.reducer;
