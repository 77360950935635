import React from "react";

const CloseIcon = () => {
    return (
        <>
            <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M10.0007 11.1667L5.91732 15.25C5.76454 15.4028 5.5701 15.4792 5.33398 15.4792C5.09787 15.4792 4.90343 15.4028 4.75065 15.25C4.59787 15.0973 4.52148 14.9028 4.52148 14.6667C4.52148 14.4306 4.59787 14.2362 4.75065 14.0834L8.83398 10L4.75065 5.91671C4.59787 5.76393 4.52148 5.56949 4.52148 5.33337C4.52148 5.09726 4.59787 4.90282 4.75065 4.75004C4.90343 4.59726 5.09787 4.52087 5.33398 4.52087C5.5701 4.52087 5.76454 4.59726 5.91732 4.75004L10.0007 8.83337L14.084 4.75004C14.2368 4.59726 14.4312 4.52087 14.6673 4.52087C14.9034 4.52087 15.0979 4.59726 15.2507 4.75004C15.4034 4.90282 15.4798 5.09726 15.4798 5.33337C15.4798 5.56949 15.4034 5.76393 15.2507 5.91671L11.1673 10L15.2507 14.0834C15.4034 14.2362 15.4798 14.4306 15.4798 14.6667C15.4798 14.9028 15.4034 15.0973 15.2507 15.25C15.0979 15.4028 14.9034 15.4792 14.6673 15.4792C14.4312 15.4792 14.2368 15.4028 14.084 15.25L10.0007 11.1667Z"
                    fill="#595959"
                />
            </svg>
        </>
    );
};

export default CloseIcon;

export const DarkCLoseIcon = () => {
    return (
        <>
            <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M10.0007 11.1668L5.91732 15.2502C5.76454 15.4029 5.5701 15.4793 5.33398 15.4793C5.09787 15.4793 4.90343 15.4029 4.75065 15.2502C4.59787 15.0974 4.52148 14.9029 4.52148 14.6668C4.52148 14.4307 4.59787 14.2363 4.75065 14.0835L8.83398 10.0002L4.75065 5.91683C4.59787 5.76405 4.52148 5.56961 4.52148 5.3335C4.52148 5.09738 4.59787 4.90294 4.75065 4.75016C4.90343 4.59738 5.09787 4.521 5.33398 4.521C5.5701 4.521 5.76454 4.59738 5.91732 4.75016L10.0007 8.8335L14.084 4.75016C14.2368 4.59738 14.4312 4.521 14.6673 4.521C14.9034 4.521 15.0979 4.59738 15.2507 4.75016C15.4034 4.90294 15.4798 5.09738 15.4798 5.3335C15.4798 5.56961 15.4034 5.76405 15.2507 5.91683L11.1673 10.0002L15.2507 14.0835C15.4034 14.2363 15.4798 14.4307 15.4798 14.6668C15.4798 14.9029 15.4034 15.0974 15.2507 15.2502C15.0979 15.4029 14.9034 15.4793 14.6673 15.4793C14.4312 15.4793 14.2368 15.4029 14.084 15.2502L10.0007 11.1668Z"
                    fill="white"
                />
            </svg>
        </>
    );
};
