import CloudCounterIcon from "assets/Icons/icons/CloudCounterIcon";
import HomeIcon from "assets/Icons/icons/HomeIcon";
import { useIsDarkTheme } from "helpers/darktheme";
import React from "react";
import { NavLink } from "react-router-dom";

const NavTab = () => {
    const isDarkTheme = useIsDarkTheme();
    return (
        <div className="w-full flex border-b border-[#F0F0F0] gap-5 text-[15px] font-medium mt-6 dark:border-[#424E59] export-cloudcounter">
            <NavLink
                to="/"
                end
                className={({ isActive }) =>
                    isActive
                        ? "text-[#E37915] border-b-2 border-[#E37915] hover:text-[#E37915] focus:outline-none"
                        : "text-[#595959] dark:text-white hover:text-orange-600 focus:outline-none"
                }
            >
                {({ isActive }) => (
                    <div className="flex items-center gap-2 px-1 pb-2">
                        <div className="py-[2px] px-[3px]">
                            <HomeIcon
                                isActive={isActive}
                                isDarkTheme={isDarkTheme}
                            />
                        </div>
                        <span className="leading-6">Project</span>
                    </div>
                )}
            </NavLink>

            <NavLink
                to="/cloud-counter"
                className={({ isActive }) =>
                    isActive
                        ? "text-[#E37915] border-b-2 border-[#E37915] hover:text-[#E37915] focus:outline-none"
                        : "text-[#595959] dark:text-white hover:text-orange-600 focus:outline-none"
                }
            >
                {({ isActive }) => (
                    <div className="flex items-center gap-2 px-1 pb-2">
                        <div className="py-[2px] px-[3px]">
                            <CloudCounterIcon
                                isActive={isActive}
                                isDarkTheme={isDarkTheme}
                            />
                        </div>
                        <span className="leading-6">Cloud Counter</span>
                    </div>
                )}
            </NavLink>
        </div>
    );
};

export default NavTab;
