// components/Overlay.js

const Overlay = ({ children, hideOverLay }) => {
    if (hideOverLay) return null; // Hide overlay when state is true

    return <div style={overlayStyles}>{children}</div>;
};

const overlayStyles = {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.5)", // semi-transparent black
    zIndex: 1000, // make sure it's above other content
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
};

const messageStyles = {
    color: "white",
    fontSize: "1.5rem",
};

export default Overlay;
