// DoughnutChart.js
import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

ChartJS.register(ArcElement, Tooltip, Legend);

const DoughnutChart = ({ dataItem }) => {
    const inprogress = dataItem?.["In Progress"] || 0;
    const Done = dataItem?.["Done"] || 0;
    const Backlog = dataItem?.["Backlog"] || 0;
    const inreview = dataItem?.["In Review"] || 0;
    const todo = dataItem?.["To Do"] || 0;

    console.log(dataItem);
    // const dataValues = [300, 50, 100, 70, 20];
    // const maxIndex = dataValues.indexOf(Math.max(...dataValues));

    const data = {
        labels: ["In Progress", "Done", "Backlog", "In Review", "To Do"],
        // Find the index of the highest value

        datasets: [
            {
                data: [Backlog, Done, inprogress, inreview, todo],
                backgroundColor: [
                    "#F0F0F0",
                    "#12B76A",
                    "#1F78B4",
                    "#E37915",
                    "#D52728",
                ],
                hoverBorderColor: [
                    "rgb(0, 255, 0)", // Green hover for Red
                    "rgb(0, 255, 0)", // Green hover for Blue
                    "rgb(0, 255, 0)", // Green hover for Yellow
                ],
                borderColor: ["white"],
                hoverBorderWidth: 2, // Makes the border more visible on hover
                hoverOffset: 4,
                // borderColor: dataValues.map(
                //     (_, index) =>
                //         index === maxIndex ? "#BEE3FD" : "transparent" // Green for the max value
                // ),
                borderWidth: 3, // Set border width
            },
        ],
    };

    const options = {
        plugins: {
            legend: {
                display: false, // Turn off legend
            },

            tooltip: {
                callbacks: {
                    label: function (tooltipItem) {
                        const total = data.datasets[0].data.reduce(
                            (acc, value) => acc + value,
                            0
                        );
                        const value =
                            data.datasets[0].data[tooltipItem.dataIndex];
                        const percentage = ((value / total) * 100).toFixed(2);
                        const label = data.labels[tooltipItem.dataIndex];
                        return [`${label}`, `${value} (${percentage}%)`];
                    },
                    title: function () {
                        return ""; // Remove the title
                    },
                },
            },
        },
        cutout: "50%", // Adjust the cutout size for spacing
    };

    // Custom plugin to display the highest dataset value with dynamic color
    const customCenterPlugin = {
        id: "centerValue",
        beforeDraw(chart) {
            const { width } = chart;
            const { ctx } = chart;
            const datasets = chart.data.datasets[0];

            if (
                !datasets ||
                !Array.isArray(datasets.data) ||
                datasets.data.length === 0
            ) {
                console.warn(
                    "Dataset data is missing or not an array",
                    datasets
                );
                return;
            }

            const highestValue = Math.max(...datasets?.data); // Get the highest value
            const highestIndex = datasets.data.indexOf(highestValue); // Get the index of the highest value
            const highestColor = datasets.backgroundColor[highestIndex]; // Get the corresponding color
            const highestLabel = chart.data.labels[highestIndex]; // Get the corresponding label

            // Add the text in the center
            ctx.save();
            ctx.font = "bold 40px Arial"; // Adjust font size and style
            ctx.fillStyle = highestColor; // Set text color to the corresponding dataset color
            ctx.textAlign = "center";
            ctx.textBaseline = "middle";

            // First Line: Value
            ctx.fillText(
                highestValue,
                width / 2,
                chart.chartArea.height / 2 - 20
            );

            // Second Line: Label
            ctx.font = "16px Arial"; // Adjust font for label
            ctx.fillStyle = "#595959"; // Label text remains black
            ctx.fillText(
                highestLabel,
                width / 2,
                chart.chartArea.height / 2 + 20
            );
            ctx.restore();
        },
    };

    // Register the plugin
    ChartJS.register(customCenterPlugin);

    return (
        <div style={{ width: "318px", height: "318px", margin: "0 auto" }}>
            <Doughnut data={data} options={options} />
        </div>
    );
};

export default DoughnutChart;
