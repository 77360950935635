const HomeIcon = ({ isActive, isDarkTheme }) => {
    const color = isActive ? "#E37915" : isDarkTheme ? "#595959" : "#FFFFFF";

    return (
        <svg
            width="18"
            height="20"
            viewBox="0 0 18 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M16 2.0002H11.82C11.6152 1.41564 11.2339 0.909159 10.7287 0.550821C10.2235 0.192483 9.61939 0 9 0C8.38061 0 7.77653 0.192483 7.27133 0.550821C6.76612 0.909159 6.38476 1.41564 6.18 2.0002H2C1.47005 2.00179 0.962265 2.21301 0.587535 2.58774C0.212805 2.96247 0.00158273 3.47026 0 4.0002V18.0002C0.00158273 18.5301 0.212805 19.0379 0.587535 19.4127C0.962265 19.7874 1.47005 19.9986 2 20.0002H16C16.5299 19.9986 17.0377 19.7874 17.4125 19.4127C17.7872 19.0379 17.9984 18.5301 18 18.0002V4.0002C17.9984 3.47026 17.7872 2.96247 17.4125 2.58774C17.0377 2.21301 16.5299 2.00179 16 2.0002ZM9 2.0002C9.19778 2.0002 9.39112 2.05885 9.55557 2.16873C9.72002 2.27861 9.84819 2.43479 9.92388 2.61752C9.99957 2.80025 10.0194 3.00131 9.98079 3.19529C9.9422 3.38927 9.84696 3.56746 9.70711 3.70731C9.56725 3.84716 9.38907 3.9424 9.19509 3.98099C9.00111 4.01957 8.80004 3.99977 8.61732 3.92408C8.43459 3.84839 8.27841 3.72022 8.16853 3.55577C8.05865 3.39132 8 3.19798 8 3.0002C8.00079 2.73523 8.1064 2.48133 8.29377 2.29397C8.48113 2.1066 8.73503 2.00099 9 2.0002ZM7 7.0002L9.79 9.7942L12.31 7.2742L11 6.0002H15V10.0002L13.724 8.6892L9.792 12.6242L7 9.8302L4.414 12.4142L3 11.0002L7 7.0002ZM16 17.0002H2V15.0002H16V17.0002Z"
                fill={color}
            />
        </svg>
    );
};

export default HomeIcon;
