import ProjectData from "./projectData";

const ProjectDataOverview = ({ dataItem }) => {
    console.log(dataItem);
    const data = [
        {
            head1: "Status",
            head2: "Count",
            status: "IssueTypes",
            type: dataItem?.Issues || 0,
        },
        { status: "Statuses", type: dataItem?.Statuses || 0 },
        { status: "Roles", type: dataItem?.["Roles"] || 0 },
        { status: "Issues", type: dataItem?.["Issues"] || 0 },
        // {
        //     status: "Issue Attachments",
        //     type: dataItem?.["Issue Attachments"] || 0,
        // },
        // {
        //     status: "Issue Attachments Sizes",
        //     type: dataItem?.["Issue Attachments Size"] || 0,
        // },
        { status: "Votes", type: dataItem?.["Votes"] || 0 },
        { status: "Comments", type: dataItem?.["Comments"] || 0 },
        { status: "Components", type: dataItem?.["Components"] || 0 },
        { status: "Versions", type: dataItem?.["Versions"] || 0 },
    ];
    const mergedData = [...data];

    return (
        <section>
            <ProjectData data={mergedData} />
        </section>
    );
};

export default ProjectDataOverview;
