import CloneCard from "components/common/cloneCard";
import DescriptionCard from "components/common/DescriptionCard";
import IssueStatus from "./IssueStatus";
import ProjectDataOverview from "./ProjectDataOverview";
import image1 from "../../assets/Homepage Assets/redesign/Icons.svg";
import image2 from "../../assets/Homepage Assets/redesign/1.svg";
import image1Dark from "../../assets/Homepage Assets/redesign/IconsDark.svg";
import image2Dark from "../../assets/Homepage Assets/redesign/1Dark.svg";
import image3Dark from "../../assets/Homepage Assets/redesign/2Dark.svg";
import { useSelector, useDispatch } from "react-redux";
import EmptyState from "components/EmptyState";
import useExtract from "helpers/custom-hooks/extract";
import { useState } from "react";
import SideDrawer from "components/SideDrawer";
import ExportSideDrawer from "components/export/ExportSidedrawer";
import CloneSideDrawer from "components/clone/CloneSideDrawer";
import MigrateSideDrawer from "components/migrate/MigrateSideDrawer";
import Sort from "components/common/sort";
import EmptyPiechart from "assets/Icons/icons/EmptyPiechart";
import TableLoading from "components/loadState/TableLoading";
import { useIsDarkTheme } from "helpers/darktheme";
import useClone from "helpers/custom-hooks/clone";
import ClonedProject from "./ClonedProject";
import { Spinner } from "components/Spinner/Spinner";
import localForage from "localforage";
import CloningProject from "components/common/cloningProgress";
import { useEffect } from "react";
import CloneSuccess from "components/common/cloneSuccess";
import Overlay from "components/common/overlayComponent";
import GetStarted from "components/tour-guide/getstarted";
import TourGuideSteps from "components/tour-guide/steps";
import { setHideOverlay } from "store/sc";

const ProjectTab = ({ joyrideStart, setJoyrideStart }) => {
    const current = useSelector((state) => state.project.currentProject) || "";
    const data = useSelector((state) => state.project.visualizeData) || "";
    const isVisualizing =
        useSelector((state) => state.project.isVisualizing) || "";
    const { extract, isExtracting } = useExtract();
    const [openExtract, setOpenExtract] = useState(false);
    const [openClone, setOpenClone] = useState(false);
    const [openMigrate, setOpenMigrate] = useState(false);
    const { clone, isCloning, cloneSuccess, setCloneSuccess } = useClone();
    const [tab, setTab] = useState(1);
    const [cloneDrawerState, setCloneDrawerState] = useState("initial");
    const hideOverlay = useSelector((state) => state.project.hideOverlay);
    const dispatch = useDispatch();

    useEffect(() => {
        const stored = localStorage.getItem("hideOverlay") === "true";
        if (stored) {
            dispatch(setHideOverlay(true));
        }
    }, [dispatch]);

    function handleExtract() {
        localForage.getItem("single_project_credentials").then((res) => {
            extract({ ...res, project_key: current.key });
        });
    }

    function handleClone() {
        setCloneDrawerState("initial");
        setCloneSuccess(false);
        localForage.getItem("single_project_credentials").then((res) => {
            clone({ ...res, project_key: current.key });
        });
    }

    useEffect(() => {
        if (cloneSuccess) {
            setCloneDrawerState("success");
        }
    }, [cloneSuccess]);

    const handleGoToClonedProject = () => {
        setTab(2);
        setOpenClone(false);
        setCloneDrawerState("initial");
    };

    const isDarkTheme = useIsDarkTheme();

    const handleMigrateCloneExtractClick = (item) => {
        if (item.id === "export") {
            setOpenExtract(true);
        } else if (item.id === "clone") {
            setOpenClone(true);
        } else {
            setOpenMigrate(true);
        }
    };

    const clonecardData = [
        {
            icon: isDarkTheme ? image1 : image1Dark,
            id: "export",
            text: "Export",
        },
        {
            icon: isDarkTheme ? image2 : image2Dark,
            id: "clone",
            text: "Clone",
        },
    ];

    const descriptionData = [
        { heading: "Project Name", key: data?.["Project Name"] },
        { heading: "Project Key", key: data?.["Project Key"] },
        { heading: "No of Issues", key: data?.counter_data?.Issues },
        {
            heading: "No of IssuesTypes",
            key: data?.counter_data?.["Issue Types"],
        },
    ];

    return (
        <>
            <section className="pt-6 pb-10 pl-10 dark:bg-[#0D1822]">
                {/* <MigrationPopUp /> */}
                <div className="flex gap-x-6 justify-between mb-6 items-center">
                    <div>
                        <p className="text-[#454545] font-medium text-xl tracking-[0.5px] dark:text-white">
                            Project
                        </p>
                    </div>

                    {/* {Object.keys(data).length > 0 && ( */}
                    <div className="flex gap-x-2 second">
                        {clonecardData.map((item, i) => (
                            <CloneCard
                                key={i}
                                img={item?.icon}
                                text={item?.text}
                                disabled={Object.keys(data).length === 0}
                                onClick={() =>
                                    handleMigrateCloneExtractClick(item)
                                }
                                className={item?.id}
                            />
                        ))}
                    </div>
                    {/* )} */}
                </div>

                {tab === 1 ? (
                    <>
                        {isVisualizing ? (
                            <>
                                <div className="grid grid-cols-4 gap-x-4">
                                    {descriptionData.map((item, i) => {
                                        return (
                                            <DescriptionCard
                                                key={i}
                                                loading={isVisualizing}
                                            />
                                        );
                                    })}
                                </div>
                                <div className="flex gap-4 mt-4">
                                    <div className="border border-[#d9d9d9] rounded-lg w-full">
                                        <div className="py-[14px] px-6 flex items-center justify-between border-b border-[#d9d9d9]">
                                            <p className="text-base font-medium text-[#262626]">
                                                Issue Statuses
                                            </p>
                                            <Sort data={[]} onSort={() => {}} />
                                        </div>
                                        <div className="text-center mt-12 mb-16 px-[100px] animate-pulse">
                                            <EmptyPiechart />
                                        </div>
                                    </div>

                                    <div className="border border-[#D9D9D9] rounded-lg w-full font-medium">
                                        <div className="py-5 px-6 text-[16px] leading-6 text-[#262626]">
                                            Project Data
                                        </div>
                                        <TableLoading rows={6} columns={2} />
                                    </div>
                                </div>
                            </>
                        ) : Object.keys(current).length === 0 ? (
                            <div className="mt-[125px]">
                                <EmptyState />
                            </div>
                        ) : (
                            <>
                                <div className="grid grid-cols-4 gap-x-4">
                                    {descriptionData.map((item, i) => {
                                        return (
                                            <DescriptionCard
                                                key={i}
                                                heading={item?.heading}
                                                subhead={item?.key}
                                            />
                                        );
                                    })}
                                </div>

                                <div className="mt-4 grid grid-cols-2 gap-x-4">
                                    <IssueStatus dataItem={data?.data} />
                                    <ProjectDataOverview
                                        dataItem={data?.counter_data}
                                    />
                                </div>
                            </>
                        )}
                    </>
                ) : (
                    <ClonedProject setTab={setTab} />
                )}
            </section>
            <SideDrawer
                isOpen={openExtract}
                onClose={() => {
                    setOpenExtract(false);
                }}
                onButtonClick={handleExtract}
                buttonLabel={isExtracting ? <Spinner /> : "Download Project"}
                title="Export"
                subtitle="Review the migration details below and proceed with migrating your project."
            >
                <ExportSideDrawer />
            </SideDrawer>

            <SideDrawer
                isOpen={openClone}
                onClose={() => {
                    setOpenClone(false);
                    setCloneDrawerState("initial"); // reset on close
                }}
                onButtonClick={
                    cloneSuccess && cloneDrawerState === "success"
                        ? handleGoToClonedProject
                        : handleClone
                }
                buttonLabel={
                    isCloning
                        ? "Cloning please wait..."
                        : cloneSuccess && cloneDrawerState === "success"
                        ? "View Cloned Project"
                        : "Start Cloning"
                }
                title={isCloning ? null : cloneSuccess ? null : "Clone"}
                subtitle={
                    isCloning
                        ? null
                        : cloneSuccess && cloneDrawerState === "success"
                        ? null
                        : "Review the migration details below and proceed with migrating your project."
                }
            >
                {isCloning ? (
                    <CloningProject />
                ) : cloneSuccess && cloneDrawerState === "success" ? (
                    <CloneSuccess
                        isSuccessIndicator={true}
                        isCloneProgressAvailable={false}
                    />
                ) : (
                    <CloneSideDrawer />
                )}
            </SideDrawer>
            <SideDrawer
                isOpen={openMigrate}
                onClose={() => setOpenMigrate(false)}
                onButtonClick={() => alert("migrate button click")}
                buttonLabel="Continue"
            >
                <MigrateSideDrawer />
            </SideDrawer>

            {!hideOverlay && (
                <Overlay hideOverLay={hideOverlay}>
                    <GetStarted
                        onClick={() => {
                            dispatch(setHideOverlay(true));
                            setJoyrideStart(true); // Start Joyride
                            // localStorage.setItem("hideOverlay", "true");
                        }}
                        onBack={() => {
                            dispatch(setHideOverlay(true));
                            // localStorage.setItem("hideOverlay", "true");
                            setJoyrideStart(false);
                        }}
                    />
                </Overlay>
            )}

            {/* Render Joyride */}
            <TourGuideSteps
                joyrideStart={joyrideStart}
                setJoyrideStart={setJoyrideStart}
            />

            {/* <CloneModal /> */}
        </>
    );
};

export default ProjectTab;
