const Status = ({ status, number, bgColor, borderColor }) => {
    return (
        <div className="flex justify-between items-center border-b border-[#F0F0F0] dark:border-[#424E59] pb-3 text-sm font-medium">
            <div className="flex items-center gap-x-2">
                <div
                    className={`w-4 h-4 border rounded-[3px]`}
                    style={{
                        backgroundColor: bgColor,
                        borderColor: borderColor,
                    }}
                ></div>
                <p className="text-[#595959] dark:text-white">{status}</p>
            </div>
            <p className="text-[#454545] dark:text-white">{number}</p>
        </div>
    );
};

export default Status;
