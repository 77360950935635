import CloningProject from "./cloningProgress";

const CloneSuccess = ({ isSuccessIndicator, isCloneProgressAvailable }) => {
    return (
        <CloningProject
            heading={"Successful Cloning"}
            subhead={
                "Your project has been successfully cloned. Check the available projects for your newly cloned project"
            }
            isCloneProgressAvailable={isCloneProgressAvailable}
            isSuccessIndicator={isSuccessIndicator}
        />
    );
};

export default CloneSuccess;
