import React from "react";
import { useSelector } from "react-redux";

const ProjectContainer = () => {
    const current = useSelector((state) => state.project.visualizeData) || "";
    return (
        <>
            <div className="bg-[#F5F5F5] dark:border-[#424E59] dark:bg-[#424E59] border border-[#D9D9D9] p-4 space-y-3 rounded-[6px] mt-6">
                <div className="flex justify-between border-b border-[#D9D9D9] pb-3">
                    <h1 className="font-medium text-[#595959] text-[14px] leading-6 dark:text-white">
                        Project Name
                    </h1>
                    <p className="text-[#262626] dark:text-white font-medium leading-7 text-[16px]">
                        {current?.["Project Name"] || ""}
                    </p>
                </div>
                <div className="flex justify-between border-b border-[#D9D9D9] pb-3">
                    <h1 className="font-medium text-[#595959] dark:text-white text-[14px] leading-6">
                        Project Key
                    </h1>
                    <p className="text-[#262626] dark:text-white font-medium leading-7 text-[16px]">
                        {current?.["Project Key"] || ""}
                    </p>
                </div>
                <div className="flex justify-between border-b border-[#D9D9D9] pb-3">
                    <h1 className="font-medium text-[#595959] dark:text-white text-[14px] leading-6">
                        No of IssueTypes
                    </h1>
                    <p className="text-[#262626] dark:text-white font-medium leading-7 text-[16px]">
                        {current?.counter_data?.Issues || 0}
                    </p>
                </div>
                <div className="flex justify-between">
                    <h1 className="font-medium text-[#595959] dark:text-white text-[14px] leading-6">
                        No of Issues
                    </h1>
                    <p className="text-[#262626] dark:text-white font-medium leading-7 text-[16px]">
                        {current?.counter_data?.["Issue Types"] || 0}
                    </p>
                </div>
            </div>
        </>
    );
};

export default ProjectContainer;
