import cloningImage from "../../assets/Homepage Assets/redesign/scrollanimation.svg";
import successIndicator from "../../assets/Homepage Assets/redesign/mark.svg";
import { Spinner } from "components/Spinner/Spinner";
import { useEffect, useState } from "react";

const CloningProject = ({
    heading,
    subhead,
    isCloneProgressAvailable = true,
    isSuccessIndicator = false,
}) => {
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setProgress((prevProgress) => {
                if (prevProgress >= 100) {
                    clearInterval(interval); // Stop updating once progress reaches 100%
                    return 100;
                }
                return prevProgress + 5; // Increment progress
            });
        }, 500); // Update progress every 500ms

        return () => clearInterval(interval); // Cleanup on unmount
    }, []);

    return (
        <div>
            <div className="mb-10 text-center">
                {isSuccessIndicator && (
                    <div className="mb-8">
                        <img
                            src={successIndicator}
                            className="w-[48px] mx-auto h-[48px]"
                            alt="cloning indicator"
                        />
                    </div>
                )}
                <p className="text-2xl font-semibold tracking-[0.5px] mb-2 text-[#262626]">
                    {heading || "Cloning Your Project"}
                </p>
                <p className="text-[#8c8c8c] text-[15px] leading-6 font-medium">
                    {subhead || "Please hold on as we clone your project"}
                </p>
            </div>

            {isCloneProgressAvailable && (
                <>
                    {/* <div>
                        <img
                            src={cloningImage}
                            className="w-full h-[8px]"
                            alt="cloning indicator"
                        />
                    </div>
                    <div className="flex justify-between mt-4">
                        <div className="flex items-center gap-x-2">
                            <img src={cloningImage2} alt="" />
                            <Spinner />
                            <p className="text-sm font-medium text-[#595959]">
                                {" "}
                                10% complete
                            </p>
                        </div>
                        <p className="text-sm font-medium text-[#595959]">
                            Cloning your projects...
                        </p>
                    </div> */}

                    {/* Progress Bar */}
                    <div className="w-full max-w-lg">
                        <div className="h-2 bg-gray-300 rounded-full">
                            <div
                                className="h-2 bg-[#009BC0] rounded-full transition-all duration-300"
                                style={{ width: `${progress}%` }}
                            ></div>
                        </div>
                    </div>

                    {/* Circular Spinner and Percentage */}
                    <div className="flex items-center justify-between w-full max-w-lg mt-4">
                        <div className="flex items-center gap-x-2">
                            <svg
                                className="animate-spin h-6 w-6 text-black"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                            >
                                <circle
                                    className="opacity-25"
                                    cx="12"
                                    cy="12"
                                    r="10"
                                    stroke="currentColor"
                                    strokeWidth="4"
                                ></circle>
                                <path
                                    className="opacity-75"
                                    fill="currentColor"
                                    d="M4 12a8 8 0 018-8V0C6.268 0 0 6.268 0 12h4z"
                                ></path>
                            </svg>
                            <p className="text-sm mt-2">{progress}% complete</p>
                        </div>
                        <p className="text-sm font-medium text-[#595959]">
                            Cloning your projects...
                        </p>
                    </div>
                </>
            )}
        </div>
    );
};

export default CloningProject;
