import Button from "components/common/button";
import image1 from "../../assets/Homepage Assets/redesign/image.png";
import HeadingSubHead from "./headingSubHead";
import { LiaTimesSolid } from "react-icons/lia";

const GetStarted = ({ onClick, onBack }) => {
    return (
        <div className="flex w-[830px] mx-auto rounded-lg">
            <div className="px-8 py-14 bg-[white] flex-1 rounded-tl-lg rounded-bl-lg">
                <p className="text-[#008DAF] text-[13px] font-semibold mb-2 leading-6 tracking-[0.5px]">
                    Welcome to Export and Count for Cloud
                </p>
                <HeadingSubHead
                    heading={
                        "Discover Exciting New Features in Export and Count for Cloud"
                    }
                    showSubHead={false}
                />
                <p className="text-[15px] leading-6 text-[#454545] mb-6">
                    <span className="font-medium">
                        Export and Count for Cloud
                    </span>{" "}
                    (formerly{" "}
                    <span className="font-medium"> Scrum Companion</span>) has
                    been redesigned for a smoother experience and easier project
                    access.
                </p>

                <p className="text-[15px] leading-6 text-[#454545] mb-2">
                    🔹 Find projects, filters, and releases faster
                </p>
                <p className="text-[15px] leading-6 text-[#454545] mb-2">
                    🔹 Enhanced navigation for improved workflow
                </p>
                <p className="text-[15px] leading-6 text-[#454545] mb-4">
                    🔹 More accessible and user-friendly interface
                </p>

                <HeadingSubHead
                    subHeading={
                        "Manage your data effortlessly with our modern, streamlined design."
                    }
                    showHeading={false}
                />
                <HeadingSubHead
                    subHeading={
                        "Take a quick tour to explore the new features!🚀"
                    }
                    showHeading={false}
                />

                <div className="flex gap-x-3 mt-6">
                    <Button btnName={"Take a Quick Tour"} onClick={onClick} />
                    <a href="http://">
                        <Button btnName={"Learn More"} btnType="white" />
                    </a>
                </div>
            </div>

            <div
                style={{
                    background:
                        " linear-gradient(180deg, #B0E0EB 0%, #B0DAF2 100%)",
                }}
                className="pl-8 flex-1 pt-6 rounded-tr-lg rounded-br-lg"
            >
                <div className="pr-4 text-right">
                    <button
                        className="p-2 text-[#595959] transition-all duration-200"
                        onClick={onBack}
                    >
                        <LiaTimesSolid size={20} className="text-[#595959]" />
                    </button>
                </div>
                <img
                    src={image1}
                    // height={450}
                    className="mb-auto rounded-br-lg mt-[2.5rem] ml-1"
                    alt="export and count quick tour"
                />
            </div>
        </div>
    );
};

export default GetStarted;
