import React, { useState } from "react";
import DarkModeIcon from "assets/Icons/icons/DarkModeIcon";
import SideMenuLogo from "assets/Icons/icons/Logo";
import SunIcon, { DarkSunIcon } from "assets/Icons/icons/SunIcon";
import ItemContainer from "components/common/ItemContainer";
import MenuTextHolder from "components/common/MenuTextHolder";
import DarkLogo from "assets/Icons/icons/DarkLogo";
import LightModeIcon from "assets/Icons/icons/LightModeIcon";
import { useDispatch } from "react-redux";
import { setTheme } from "store/sc";
import { useIsDarkTheme } from "helpers/darktheme";
import DarkIcon, { DarkMoonIcon } from "assets/Icons/icons/DarkIcon";
import MoonIcon from "assets/Icons/icons/DarkIcon";

const TopBar = () => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const dispatch = useDispatch();
    const isDarkTheme = useIsDarkTheme();

    const toggleTheme = (newTheme) => {
        dispatch(setTheme(newTheme));
        document.documentElement.classList.toggle("dark", newTheme === "dark");
        setDropdownOpen(false);
    };

    return (
        <div className="border-b border-[#D9D9D9] dark:border-[#424E59] w-full sticky top-0 flex z-50 bg-white dark:bg-[#0D1822]">
            <div className="w-[21%] p-4 border-r border-[D9D9D9] dark:border-[#424E59]">
                {isDarkTheme ? <SideMenuLogo /> : <DarkLogo />}
            </div>
            <div className="flex items-center py-5 justify-end w-[79%] pr-16 ">
                <div className="relative dark:text-white">
                    <ItemContainer
                        icon={
                            isDarkTheme ? <DarkModeIcon /> : <LightModeIcon />
                        }
                        title={"Theme"}
                        style={
                            "border border-[#D9D9D9] shadow dark:bg-[#424E59] dark:border-[#616B74]"
                        }
                        onClick={() => setDropdownOpen(!dropdownOpen)}
                    />
                    {dropdownOpen && (
                        <div className="absolute mt-2 w-40 bg-white dark:bg-[#111F2C] border border-gray-300 dark:border-[#424E59] rounded-lg shadow-lg">
                            <button
                                onClick={() => toggleTheme("light")}
                                className="flex items-center gap-2 p-2 w-full hover:bg-gray-100 dark:hover:bg-gray-800"
                            >
                                {isDarkTheme ? <SunIcon /> : <DarkSunIcon />}
                                Light Mode
                            </button>
                            <button
                                onClick={() => toggleTheme("dark")}
                                className="flex items-center gap-2 p-2 w-full hover:bg-gray-100 dark:hover:bg-gray-800"
                            >
                                {isDarkTheme ? <MoonIcon /> : <DarkMoonIcon />}
                                Dark Mode
                            </button>
                        </div>
                    )}
                </div>

                <div className="h-full border border-[#F0F0F0] dark:border-gray-600 mx-4 py-1" />

                <div className="flex">
                    <MenuTextHolder
                        title="Docs"
                        link="https://alluvium-hq.atlassian.net/wiki/external/M2M3OTZiYmZjNDU1NDVmNGE5NmRhYzJiNzYxNjlhOWE"
                        onClick={() => {}}
                    />
                    <MenuTextHolder
                        title="Community"
                        link="https://community.atlassian.com/t5/custom/page/page-id/create-post-step-1?add-tags=atlassian-marketplace%2Caddon-alluvium-scrum-companion%2Ccloud"
                        onClick={() => {}}
                    />
                    <MenuTextHolder
                        title="Feedback"
                        link="https://alluvium-hq.atlassian.net/servicedesk/customer/portal/11"
                        onClick={() => {}}
                    />
                    <MenuTextHolder
                        title="Support"
                        link="https://alluvium-hq.atlassian.net/servicedesk/customer/portal/11"
                        onClick={() => {}}
                    />
                </div>
            </div>
        </div>
    );
};

export default TopBar;
