import React from "react";

const AttachmentIcon = () => {
    return (
        <>
            <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M0 6V2.98023e-08H1.33333V6C1.33333 6.53043 1.54405 7.03914 1.91912 7.41421C2.29419 7.78929 2.8029 8 3.33333 8C3.86377 8 4.37247 7.78929 4.74755 7.41421C5.12262 7.03914 5.33333 6.53043 5.33333 6V2C5.33333 1.82319 5.2631 1.65362 5.13807 1.5286C5.01305 1.40357 4.84348 1.33333 4.66667 1.33333C4.48986 1.33333 4.32029 1.40357 4.19526 1.5286C4.07024 1.65362 4 1.82319 4 2V6.66667H2.66667V2C2.66667 1.73736 2.7184 1.47728 2.81891 1.23463C2.91942 0.991982 3.06674 0.771504 3.25245 0.585786C3.43817 0.400069 3.65865 0.25275 3.9013 0.152241C4.14395 0.0517315 4.40402 0 4.66667 0C4.92931 0 5.18938 0.0517315 5.43203 0.152241C5.67469 0.25275 5.89516 0.400069 6.08088 0.585786C6.2666 0.771504 6.41392 0.991982 6.51443 1.23463C6.61494 1.47728 6.66667 1.73736 6.66667 2V6C6.66667 6.88406 6.31548 7.7319 5.69036 8.35702C5.06523 8.98214 4.21739 9.33333 3.33333 9.33333C2.44928 9.33333 1.60143 8.98214 0.976311 8.35702C0.351189 7.7319 0 6.88406 0 6Z"
                    fill="white"
                />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M16.6663 0H7.99967V6C7.99967 7.23768 7.50801 8.42466 6.63284 9.29983C5.75767 10.175 4.57068 10.6667 3.33301 10.6667H1.33301V18C1.33301 18.5304 1.54372 19.0391 1.91879 19.4142C2.29387 19.7893 2.80257 20 3.33301 20H16.6663C17.1968 20 17.7055 19.7893 18.0806 19.4142C18.4556 19.0391 18.6663 18.5304 18.6663 18V2C18.6663 1.46957 18.4556 0.960859 18.0806 0.585786C17.7055 0.210714 17.1968 0 16.6663 0ZM14.6663 5.33333H9.33301V6.66667H14.6663V5.33333ZM14.6663 9.33333H9.33301V10.6667H14.6663V9.33333ZM5.33301 13.3333H14.6663V14.6667H5.33301V13.3333Z"
                    fill="white"
                />
            </svg>
        </>
    );
};

export default AttachmentIcon;
