import React from "react";
import NavTab from "components/navigation/NavTab";
import SideMenu from "components/sidemenu/SideMenu";
import TopBar from "components/topBar/TopBar";
import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

const DashboardLayout = ({ joyrideStart }) => {
    const current = useSelector((state) => state.project.currentProject) || "";

    return (
        <div
            className={`bg-white dark:bg-[#0D1822] h-screen flex flex-col ${
                Object.keys(current).length === 0
                    ? "overflow-y-clip"
                    : " overflow-auto"
            } `}
        >
            <TopBar />
            <div className="flex flex-grow dark:bg-[#0D1822]">
                <SideMenu joyrideStart={joyrideStart} />
                <div className="flex-grow ml-[21%] pt-10 pr-10">
                    <div className="w-full pl-10">
                        <p className="font-medium text-[24px] leading-8 text-[#262626] dark:text-white">
                            {current.name}
                        </p>
                        <NavTab />
                    </div>

                    <div className="pb-32">
                        <Outlet />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DashboardLayout;
