import ProjectTab from "components/redesign/projectTab";
import React from "react";

const Projects = ({ joyrideStart, setJoyrideStart }) => {
    return (
        <div>
            <ProjectTab
                joyrideStart={joyrideStart}
                setJoyrideStart={setJoyrideStart}
            />
        </div>
    );
};

export default Projects;
