import React, { useState, useEffect, useMemo, useCallback } from "react";
import {
    titleCase,
    sortObjectKeys,
    formatDate,
    extractChartData,
} from "../../utils/util.ts";
import Table from "../Table/table";
import PreviousCounts from "../previous-counts/previous-counts";
import BarChartView from "../bar-chart/index.jsx";
import { useSelector } from "react-redux";
import Attachments from "components/attachments/attachments.js";

const JiraSoftwareCounts = () => {
    const [softwareCountItems, setSoftwareCountItems] = useState([]);
    const [currentViewId, setCurrentViewId] = useState(1);

    const software_counts = useSelector(
        (state) => state.cloudCounter.software_counts
    );

    const { chartData } = useMemo(
        () => extractChartData(software_counts),
        [software_counts]
    );

    const sortedList = useMemo(
        () =>
            software_counts.length > 0
                ? sortObjectKeys(software_counts[0]?.data)
                : [],
        [software_counts]
    );

    const currentCount = software_counts[0]?.data;
    const previousCount = useMemo(
        () =>
            software_counts.find((info) => info.id === currentViewId)?.data ||
            {},
        [currentViewId, software_counts]
    );

    const date = useMemo(() => {
        const selectedDate = software_counts.find(
            (info) => info.id === currentViewId
        )?.date;
        return formatDate(selectedDate, "LL");
    }, [currentViewId, software_counts]);

    const updateDataTable = useCallback(() => {
        const items = sortedList.map((row, id) => {
            const title = titleCase(row[0]);
            const currentValue = currentCount[row[0]];
            const previousValue = previousCount[row[0]] ?? 0;
            const percentageDiff = previousValue
                ? (
                      ((currentValue - previousValue) / previousValue) *
                      100
                  ).toFixed(2)
                : "N/A";

            return {
                id,
                title,
                currentCount: currentValue,
                previousCount: previousValue,
                percentageDiff: percentageDiff + "%",
            };
        });
        setSoftwareCountItems(items);
    }, [currentCount, previousCount, sortedList]);

    const previousData = useMemo(
        () => software_counts.slice(2),
        [software_counts]
    );

    useEffect(() => {
        updateDataTable();
    }, [currentViewId, updateDataTable]);

    return (
        <>
            {Object.keys(chartData).length > 0 && (
                <BarChartView
                    datas={chartData}
                    date={date}
                    title={"Jira Software"}
                />
            )}
            <div className="border border-[#D9D9D9] dark:border-[#424E59] shadow-sm rounded-lg mt-6">
                <Table data={softwareCountItems} />
                {/* {currentViewId > 1 && (
                    <div className="d-flex justify-content-end mt-5">
                        <button
                            className={`btn btn-primary`}
                            onClick={() => setCurrentViewId(1)}
                        >
                            Reset Table Data
                        </button>
                    </div>
                )} */}
            </div>
            <Attachments />
        </>
    );
};

export default JiraSoftwareCounts;
