import React from "react";

const ItemContainer = ({ icon, title, style, onClick }) => {
    return (
        <div
            onClick={onClick}
            className={`flex gap-2 py-2 px-4 rounded-[8px] cursor-pointer ${style} theme`}
        >
            <span>{icon}</span>
            <p className="font-semibold text-[14px] leading-5 text-[#595959] dark:text-white">
                {title}
            </p>
        </div>
    );
};

export default ItemContainer;
