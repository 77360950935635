import Sort from "components/common/sort";
import DoughnutChart from "./chart/DoughnutChart";
import Status from "components/common/status";
import { useState } from "react";
import { useEffect } from "react";

const IssueStatus = ({ dataItem = {} }) => {
    console.log(dataItem["Status statusCategory"]);
    const statusCategory = dataItem?.["Status statusCategory"] || {};

    const inprogress = statusCategory?.["In Progress"] || 0;
    const Done = statusCategory?.["Done"] || 0;
    const Backlog = statusCategory?.["Backlog"] || 0;
    const inreview = statusCategory?.["In Review"] || 0;
    const todo = statusCategory?.["To Do"] || 0;

    const initialStatusCount = [
        {
            bgColor: "#12b76a",
            borderColor: "transparent",
            number: Done,
            status: "Done",
        },
        {
            bgColor: "#d52728",
            borderColor: "transparent",
            number: todo,
            status: "To Do",
        },
        {
            bgColor: "#1f78b4",
            borderColor: "transparent",
            number: inprogress,
            status: "In Progress",
        },
        {
            bgColor: "#E37915",
            borderColor: "transparent",
            number: inreview,
            status: "In Review",
        },
        {
            bgColor: "#F0F0F0",
            borderColor: "transparent",
            number: Backlog,
            status: "Backlog",
        },
    ];

    const [statusCount, setStatusCount] = useState(initialStatusCount);

    useEffect(() => {
        setStatusCount(initialStatusCount);
    }, [Done, inprogress, Backlog, inreview, todo, initialStatusCount]);

    return (
        <div className="border border-[#d9d9d9] dark:border-[#424E59] rounded-lg">
            <div className="py-[14px] px-6 flex items-center justify-between border-b border-[#d9d9d9] dark:border-[#424E59]">
                <p className="text-base font-medium text-[#262626] dark:text-white">
                    {" "}
                    Issue Statuses
                </p>
                <Sort data={statusCount} onSort={setStatusCount} />
            </div>

            <div className="text-center mt-12 mb-16">
                <DoughnutChart dataItem={statusCategory} />
            </div>

            <div className="flex flex-col gap-y-6 px-16 pb-16">
                {statusCount.map((item, i) => {
                    return (
                        <Status
                            key={i}
                            status={item?.status}
                            number={item?.number}
                            bgColor={item?.bgColor}
                            borderColor={item?.borderColor}
                        />
                    );
                })}
            </div>
        </div>
    );
};

export default IssueStatus;
