import ProjectData from "./projectData";
import { useSelector } from "react-redux";
import { IoArrowBack } from "react-icons/io5";
const ClonedProject = ({ setTab }) => {
    const cloneData = useSelector((state) => state.project.cloneData);
    // console.log(cloneData?.compare_data);
    const clone__data = cloneData?.compare_data;
    const data = [
        {
            status: "Comments",
            type: clone__data?.Comments?.source,
            head1: "Name",
            head2: "Source",
            head3: "Clone",
            clone: clone__data?.Comments?.clone,
        },
        {
            status: "Components",
            type: clone__data?.Components?.source || 0,
            clone: clone__data?.Components?.clone || 0,
        },
        {
            status: "Issue Attachments",
            type: clone__data?.["Issue Attachments"]?.source || 0,
            clone: clone__data?.["Issue Attachments"]?.clone || 0,
        },
        {
            status: "Issue Attachments Size",
            type: clone__data?.["Issue Attachments Size"]?.source || 0,
            clone: clone__data?.["Issue Attachments Size"]?.clone || 0,
        },
        {
            status: "Issue Types",
            type: clone__data?.["Issue Types"]?.source || 0,
            clone: clone__data?.["Issue Types"]?.clone || 0,
        },
        {
            status: "Issues",
            type: clone__data?.["Issues"]?.source || 0,
            clone: clone__data?.["Issues"]?.clone || 0,
        },
        {
            status: "Roles",
            type: clone__data?.["Roles"]?.source || 0,
            clone: clone__data?.["Roles"]?.clone || 0,
        },
        {
            status: "Statuses",
            type: clone__data?.["Statuses"]?.source || 0,
            clone: clone__data?.["Statuses"]?.clone || 0,
        },
        {
            status: "Versions",
            type: clone__data?.["Versions"]?.source || 0,
            clone: clone__data?.["Versions"]?.clone || 0,
        },
        {
            status: "Votes",
            type: clone__data?.["Votes"]?.source || 0,
            clone: clone__data?.["Votes"]?.clone || 0,
        },
        {
            status: "Worklogs",
            type: clone__data?.["Worklogs"]?.source || 0,
            clone: clone__data?.["Worklogs"]?.clone || 0,
        },
    ];
    return (
        <section className="">
            <button
                onClick={() => setTab(1)}
                className="flex items-center gap-x-2"
            >
                {" "}
                <IoArrowBack size={20} />
                Back
            </button>
            <p className="font-medium text-[15px] leading-6 text-[#595959] py-4">
                Newly Cloned
            </p>
            <ProjectData threeColumn={true} data={data} />
        </section>
    );
};

export default ClonedProject;
