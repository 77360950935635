import { useState } from "react";
import Axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import useReponseErr from "../reponseErr";
import { setDashboardData, setReleases, setFilters } from "store/sc";

function useSelectedType() {
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const dispatch = useDispatch();
    const [data, setData] = useState({});
    const { responseErr } = useReponseErr();

    function getDashBoardData(values) {
        setLoading(true);
        Axios.post(`/systemdashboard`, { token: values })
            .then((res) => {
                dispatch(setDashboardData(res.data.data));
                dispatch(setReleases(res.data.data.releases));
                dispatch(setFilters(res.data.data.filters));
                setData(res.data);
                setSuccess(true);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                responseErr(err);
                setSuccess(false);
            });
    }

    return {
        loading,
        getDashBoardData,
        data,
        success,
    };
}

export default useSelectedType;
