import React, { useState, useEffect, useCallback, useMemo } from "react";
import { FaFilePdf } from "react-icons/fa";
import { FaRegFileExcel } from "react-icons/fa";
import { BsFiletypeCsv } from "react-icons/bs";
import {
    exportAsPdfFile,
    formatDataForExport,
    formatDate,
    generatePreliminaryHeaders,
    sortObjectKeys,
    titleCase,
} from "../../utils/util.ts";
import * as XLSX from "xlsx";
import "./exports.scss";
import ExportIcon, { DarkExportIcon } from "assets/Icons/icons/ExportIcon.jsx";
import ArrowDown from "assets/Icons/icons/ArrowDown.jsx";
import { useIsDarkTheme } from "helpers/darktheme.js";

const Exports = (props) => {
    const { info } = props;
    const [exportData, setExportData] = useState([]);
    const [excelHeader, setExcelHeader] = useState([]);
    const title = useMemo(() => {
        let tit = `${info?.title} Count`;
        return tit;
    }, [info]);
    const isDarkTheme = useIsDarkTheme();

    useEffect(() => {
        if (info?.data?.length > 0) {
            let headers = ["Date"];
            const rest = info.data;
            const sortedData = sortObjectKeys(rest[0]?.data);
            sortedData.forEach((row) => {
                const title = titleCase(row[0]);
                headers.push(title);
            });

            setExcelHeader([headers]);

            let tempExportData = [];

            rest.forEach((prev) => {
                let holder = {};
                sortedData.forEach((row) => {
                    holder[row[0]] = prev.data[row[0]];
                });
                tempExportData.push({ date: formatDate(prev.date), ...holder });
            });
            setExportData(tempExportData);
        }
    }, [info]);

    const exportToExcelOrCsv = useCallback(
        (exportAsCsv = false) => {
            if (exportAsCsv) {
                console.log("exportAsCsv");
            }
            if (!exportAsCsv) {
                console.log("!exportAsCsv");
            }

            if (exportData && exportData.length) {
                const workbook = XLSX.utils.book_new();
                const worksheet = XLSX.utils.json_to_sheet(exportData);
                XLSX.utils.book_append_sheet(workbook, worksheet, "Products");

                // customize header names
                XLSX.utils.sheet_add_aoa(worksheet, excelHeader);

                XLSX.writeFile(
                    workbook,
                    title + (exportAsCsv ? ".csv" : ".xlsx"),
                    {
                        compression: true,
                        bookType: exportAsCsv ? "csv" : "xlsx",
                    }
                );
            }
        },
        [exportData, excelHeader, title]
    );

    const exportToPdf = useCallback(() => {
        console.log("exportToPdf");

        if (info?.data) {
            exportAsPdfFile(
                formatDataForExport(info?.data),
                title,
                generatePreliminaryHeaders(info?.data),
                "landscape"
            );
        }
    }, [info, title]);

    const options = [
        {
            label: "PDF file",
            icon: <FaFilePdf color="red" />,
            onClick: () => exportToPdf(),
        },
        {
            label: "Excel file",
            icon: <FaRegFileExcel color="green" />,
            onClick: () => exportToExcelOrCsv(),
        },
        {
            label: "CSV file",
            icon: <BsFiletypeCsv color="blue" />,
            onClick: () => exportToExcelOrCsv(true),
        },
    ];

    const [isOpen, setIsOpen] = useState(false);
    const [selected, setSelected] = useState("Export Data");
    return (
        <>
            <div className="relative inline-block w-48">
                <button
                    onClick={() => setIsOpen(!isOpen)}
                    className="flex justify-between items-center w-full px-4 py-2 dark:bg-[#424E59] border border-gray-300 dark:border-[#616B74] rounded-lg shadow-md hover:bg-gray-200"
                >
                    <div className="flex gap-2 items-center">
                        {isDarkTheme ? <ExportIcon /> : <DarkExportIcon />}
                        <p className="dark:text-white">Export Data</p>
                    </div>
                    <div className={`${isOpen ? "rotate-180" : ""}`}>
                        <ArrowDown color={isDarkTheme ? "" : "#ffffff"} />
                    </div>
                </button>
                {isOpen && (
                    <ul className="absolute w-full mt-1 bg-white border border-gray-300 rounded-lg shadow-lg">
                        {options.map((option, index) => (
                            <li
                                key={index}
                                onClick={() => {
                                    setSelected(option.label);
                                    option.onClick();
                                    setIsOpen(false);
                                }}
                                className="px-4 py-2 cursor-pointer hover:bg-gray-200 flex items-center"
                            >
                                {option.icon && (
                                    <span className="mr-2">{option.icon}</span>
                                )}
                                {option.label}
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        </>
    );
};

export default Exports;
