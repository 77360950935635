import { Tooltip } from "flowbite-react";
import { Spinner } from "components/Spinner/Spinner";

const CloneCard = ({
    disabled = false,
    img,
    text,
    onClick,
    isExtracting,
    isCloning = false,
    className,
}) => {
    const button = (
        <button
            className={`border border-[#D9D9D9] flex items-center px-4 py-2 rounded-lg dark:bg-[#424E59] dark:border-[#424E59] ${
                disabled ? " opacity-40" : null
            } ${className}`}
            style={{ boxShadow: "0px 1px 2px 0px #10182808" }}
            disabled={disabled}
            data-tooltip-target="tooltip-default"
            type="button"
            onClick={onClick}
        >
            {isExtracting || isCloning ? (
                <Spinner />
            ) : (
                <img width={20} height={20} src={img} alt="clone card" />
            )}

            <span className="ml-2 text-sm font-semibold text-[#595959] dark:text-white mb-[-4px]">
                {text}
            </span>
        </button>
    );
    return disabled ? (
        <Tooltip
            content={`${disabled && "Select a project to enable this action"}`}
        >
            {button}
        </Tooltip>
    ) : (
        button
    );
};

export default CloneCard;
