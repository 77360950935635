import React from "react";

const MenuTextHolder = ({ title, onClick, link }) => {
    return (
        <div
            onClick={onClick}
            className="cursor-pointer px-4 py-2 font-semibold text-[14px] leading-5 text-[#595959] dark:text-white"
        >
            {link && (
                <a href={link} target="_blank" rel="noopener noreferrer">
                    {title}
                </a>
            )}
        </div>
    );
};

export default MenuTextHolder;
