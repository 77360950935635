import React, { useState } from "react";
import DashboardLayout from "layouts/DashboardLayout";
import MainCloudCounter from "pages/cloud-counter";
import Projects from "pages/home";
import { Route, Routes } from "react-router-dom";

const Router = () => {
    const [joyrideStart, setJoyrideStart] = useState(false);

    return (
        <Routes>
            <Route
                path=""
                element={<DashboardLayout joyrideStart={joyrideStart} />}
            >
                <Route
                    path=""
                    element={
                        <Projects
                            joyrideStart={joyrideStart}
                            setJoyrideStart={setJoyrideStart}
                        />
                    }
                />
                <Route path="cloud-counter" element={<MainCloudCounter />} />
            </Route>
        </Routes>
    );
};

export default Router;
