const ProjectData = ({ threeColumn = false, data }) => {
    const tableHead = data.slice(0, 1);
    return (
        <div>
            <div className="border-b border border-[#d9d9d9] dark:border-[#424E59] rounded-lg">
                <div className="py-[20px] pl-6">
                    <p className="text-base font-medium text-[#262626] dark:text-white">
                        {" "}
                        Project Data
                    </p>
                </div>
                <table className="w-full border-collapse border-[#d9d9d9] border-t-[1px] mt-0 overflow-hidden shadow-sm">
                    <thead className="bg-[#F9FAFB] dark:bg-[#424E59] border-[#d9d9d9] border-b-[1px] dark:text-white text-[#667085] text-[15px] leading-[1.5rem] font-medium">
                        {tableHead.map((item, i) => {
                            return (
                                <tr key={i}>
                                    <th
                                        className={`"px-6 py-3 text-left ${
                                            threeColumn ? "w-[35%]" : "w-[65%]"
                                        } `}
                                        style={{ paddingLeft: "1.5rem" }}
                                    >
                                        {item?.head1}
                                    </th>
                                    <th
                                        className="px-6 py-3 text-left w-[35%]"
                                        style={{ paddingLeft: "1.5rem" }}
                                    >
                                        {item?.head2}
                                    </th>
                                    {threeColumn && (
                                        <th
                                            className="px-6 py-3 text-left w-[35%]"
                                            style={{ paddingLeft: "1.5rem" }}
                                        >
                                            {item?.head3}
                                        </th>
                                    )}
                                </tr>
                            );
                        })}
                    </thead>
                    <tbody className="text-gray-600 dark:text-white text-[15px] leading-[1.5rem] divide-y divide-gray-200 dark:divide-[#424E59]">
                        {data.map((item, index) => (
                            <tr
                                key={index}
                                className="hover:bg-gray-50 dark:hover:bg-[#424E59] transition duration-200 ease-in-out"
                            >
                                <td
                                    className="p-6"
                                    style={{ paddingLeft: "1.5rem" }}
                                >
                                    {item?.status}
                                </td>
                                <td
                                    className="p-6"
                                    style={{ paddingLeft: "1.5rem" }}
                                >
                                    {item?.type}
                                </td>
                                {threeColumn && (
                                    <td
                                        className="p-6"
                                        style={{ paddingLeft: "1.5rem" }}
                                    >
                                        {item?.clone}
                                    </td>
                                )}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default ProjectData;
