import ArrowDown from "assets/Icons/icons/ArrowDown";
import SearchInput from "components/common/SearchInput";
import ProjectList from "components/list-project";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

const SideMenu = ({ joyrideStart }) => {
    const [searchQuery, setSearchQuery] = useState("");
    const [selectedProject, setSelectedProject] = useState("");
    const [selectedMenu, setSelectedMenu] = useState([]);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const isLoading = useSelector((state) => state.project.projectsLoading);
    const projectssearch = useSelector((state) => state.project.projects);
    const releases = useSelector((state) => state.project.releases);
    const filters = useSelector((state) => state.project.filters);

    const menuItems = [
        {
            bgColor: "#009BC0",
            code: "pr",
            title: "Projects",
            count: `${projectssearch?.length} projects`,
        },
        {
            bgColor: "#D97706",
            code: "re",
            title: "Releases",
            count: `${releases?.length} releases`,
        },
        {
            bgColor: "#22C55E",
            code: "fl",
            title: "Filters",
            count: `${filters?.length} filters`,
        },
    ];
    const [selectedMenuItem, setSelectedMenuItem] = useState(menuItems[0]);

    useEffect(() => {
        setSelectedMenu(projectssearch);
    }, [projectssearch]);
    const hideOverlay = useSelector((state) => state.project.hideOverlay);

    const filteredProjects = useMemo(() => {
        return selectedMenu.filter((project) =>
            project.name.toLowerCase().includes(searchQuery.toLowerCase())
        );
    }, [searchQuery, projectssearch, selectedMenu]);

    const handleItemClick = (item) => {
        setSelectedMenuItem(item);
        if (item.code === "pr") {
            setSelectedMenu(projectssearch);
        } else if (item.code === "re") {
            setSelectedMenu(releases);
        } else {
            setSelectedMenu(filters);
        }
        setIsDropdownOpen(false);
    };

    return (
        <div className="w-[21%] border-r border-[#D9D9D9] dark:border-[#424E59] h-screen fixed left-0 top-20 dark:text-white">
            <div className={`h-full ${joyrideStart ? "" : "!overflow-y-auto"}`}>
                {/* overflow-y-auto */}
                <div
                    className="p-4 border-b border-[#D9D9D9] dark:border-[#424E59] flex justify-between items-center cursor-pointer project-section"
                    onClick={() => setIsDropdownOpen(!isDropdownOpen)} // Toggle on click
                >
                    <div className="text-[13px] font-semibold flex items-center gap-4">
                        <div
                            style={{
                                backgroundColor: selectedMenuItem.bgColor,
                            }}
                            className={`text-[#fff] leading-4 p-[6px] rounded-md`}
                        >
                            {selectedMenuItem.code.toUpperCase()}
                        </div>
                        <div>
                            <p className="text-[16px] text-[#262626] dark:text-white leading-4">
                                {selectedMenuItem.title}
                            </p>
                            <p className="font-medium text-[13px] text-[#8C8C8C] dark:text-white leading-4 mt-[6px]">
                                {selectedMenuItem.count}
                            </p>
                        </div>
                    </div>

                    <div
                        className={`cursor-pointer transition-transform ${
                            isDropdownOpen ? "rotate-180" : ""
                        }`}
                    >
                        <ArrowDown />
                    </div>
                </div>

                {/* Conditionally Render Sections */}
                {isDropdownOpen && (
                    <div className="absolute mt-2 w-full bg-white dark:bg-[#111F2C] border border-[#D9D9D9] dark:border-[#424E59] rounded-lg shadow-md z-40 py-2">
                        {menuItems.map((item, index) => (
                            <div
                                key={index}
                                className="px-4 py-2 flex items-center gap-4 font-semibold cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-700 transition"
                                onClick={() => handleItemClick(item)} // ⬅️ Click action added
                            >
                                <div
                                    className="text-white leading-4 p-[6px] rounded-md"
                                    style={{ backgroundColor: item.bgColor }}
                                >
                                    {item.code.toUpperCase()}
                                </div>
                                <div>
                                    <p className="text-[16px] text-[#262626] dark:text-white leading-4">
                                        {item.title}
                                    </p>
                                    <p className="font-medium text-[13px] text-[#8C8C8C] dark:text-white leading-4 mt-[6px]">
                                        {item.count}
                                    </p>
                                </div>
                            </div>
                        ))}
                    </div>
                )}

                <div className="project-list px-4 pt-4">
                    <h1 className="text-lg font-semibold mb-4">
                        Available Projects
                    </h1>
                    <SearchInput
                        searchQuery={searchQuery}
                        setSearchQuery={setSearchQuery}
                    />
                </div>

                <>
                    {isLoading ? (
                        <div className="w-full px-4 space-y-1">
                            {[...Array(14)].map((_, i) => (
                                <div
                                    key={i}
                                    className={`animate-pulse rounded-lg flex gap-4 items-center py-3 px-4 ${
                                        i === 0 ? "bg-[#FCF2E8] w-full" : ""
                                    }`}
                                >
                                    <div
                                        className={`h-6 w-6 rounded-[3px] ${
                                            i === 0
                                                ? "bg-[#F6D5B6E5]"
                                                : "bg-[#F0F0F0]"
                                        }`}
                                    ></div>
                                    <div
                                        className={`h-4 rounded-[3px] ${
                                            i === 0
                                                ? "bg-[#F6D5B6E5]"
                                                : "bg-[#F0F0F0]"
                                        } w-4/6`}
                                    ></div>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <ProjectList
                            projects={filteredProjects}
                            selectedProject={selectedProject}
                            setSelectedProject={setSelectedProject}
                        />
                    )}
                    <div />
                </>
            </div>
        </div>
    );
};
export default SideMenu;
