import React from "react";

const FolderIcon = () => {
    return (
        <>
            <svg
                width="200"
                height="140"
                viewBox="0 0 200 140"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M162 0H42C36.4772 0 32 4.47715 32 10V130C32 135.523 36.4772 140 42 140H162C167.523 140 172 135.523 172 130V10C172 4.47715 167.523 0 162 0Z"
                    fill="url(#paint0_linear_372_2219)"
                />
                <g filter="url(#filter0_d_372_2219)">
                    <path
                        d="M146 10H11C8.23858 10 6 12.2386 6 15V40C6 42.7614 8.23858 45 11 45H146C148.761 45 151 42.7614 151 40V15C151 12.2386 148.761 10 146 10Z"
                        fill="white"
                    />
                </g>
                <path
                    d="M75 18H49C47.3431 18 46 19.3431 46 21C46 22.6569 47.3431 24 49 24H75C76.6569 24 78 22.6569 78 21C78 19.3431 76.6569 18 75 18Z"
                    fill="#B0E0EB"
                />
                <path
                    d="M93 31H49C47.3431 31 46 32.3431 46 34C46 35.6569 47.3431 37 49 37H93C94.6569 37 96 35.6569 96 34C96 32.3431 94.6569 31 93 31Z"
                    fill="#8AD1E2"
                />
                <path
                    d="M33 13H14C11.2386 13 9 15.2386 9 18V37C9 39.7614 11.2386 42 14 42H33C35.7614 42 38 39.7614 38 37V18C38 15.2386 35.7614 13 33 13Z"
                    fill="#33AFCD"
                />
                <path
                    d="M27.6667 22.6667H24.88C24.7435 22.277 24.4893 21.9393 24.1524 21.7004C23.8156 21.4615 23.4129 21.3332 23 21.3332C22.5871 21.3332 22.1844 21.4615 21.8476 21.7004C21.5107 21.9393 21.2565 22.277 21.12 22.6667H18.3333C17.98 22.6677 17.6415 22.8085 17.3917 23.0583C17.1419 23.3082 17.0011 23.6467 17 24V33.3333C17.0011 33.6866 17.1419 34.0252 17.3917 34.275C17.6415 34.5248 17.98 34.6656 18.3333 34.6667H27.6667C28.02 34.6656 28.3585 34.5248 28.6083 34.275C28.8581 34.0252 28.9989 33.6866 29 33.3333V24C28.9989 23.6467 28.8581 23.3082 28.6083 23.0583C28.3585 22.8085 28.02 22.6677 27.6667 22.6667ZM23 22.6667C23.1319 22.6667 23.2607 22.7058 23.3704 22.779C23.48 22.8523 23.5655 22.9564 23.6159 23.0782C23.6664 23.2 23.6796 23.3341 23.6539 23.4634C23.6281 23.5927 23.5646 23.7115 23.4714 23.8047C23.3782 23.898 23.2594 23.9615 23.1301 23.9872C23.0007 24.0129 22.8667 23.9997 22.7449 23.9492C22.6231 23.8988 22.5189 23.8133 22.4457 23.7037C22.3724 23.5941 22.3333 23.4652 22.3333 23.3333C22.3339 23.1567 22.4043 22.9874 22.5292 22.8625C22.6541 22.7376 22.8234 22.6672 23 22.6667ZM21.6667 26L23.5267 27.8627L25.2067 26.1827L24.3333 25.3333H27V28L26.1493 27.126L23.528 29.7493L21.6667 27.8867L19.9427 29.6093L19 28.6667L21.6667 26ZM27.6667 32.6667H18.3333V31.3333H27.6667V32.6667Z"
                    fill="white"
                />
                <g filter="url(#filter1_d_372_2219)">
                    <path
                        d="M54 53H189C190.326 53 191.598 53.5268 192.536 54.4645C193.473 55.4021 194 56.6739 194 58V83C194 84.3261 193.473 85.5979 192.536 86.5355C191.598 87.4732 190.326 88 189 88H54C52.6739 88 51.4021 87.4732 50.4645 86.5355C49.5268 85.5979 49 84.3261 49 83V58C49 56.6739 49.5268 55.4021 50.4645 54.4645C51.4021 53.5268 52.6739 53 54 53V53Z"
                        fill="white"
                    />
                </g>
                <path
                    d="M118 61H92C90.3431 61 89 62.3431 89 64C89 65.6569 90.3431 67 92 67H118C119.657 67 121 65.6569 121 64C121 62.3431 119.657 61 118 61Z"
                    fill="#B0E0EB"
                />
                <path
                    d="M136 74H92C90.3431 74 89 75.3431 89 77C89 78.6569 90.3431 80 92 80H136C137.657 80 139 78.6569 139 77C139 75.3431 137.657 74 136 74Z"
                    fill="#8AD1E2"
                />
                <path
                    d="M76 56H57C54.2386 56 52 58.2386 52 61V80C52 82.7614 54.2386 85 57 85H76C78.7614 85 81 82.7614 81 80V61C81 58.2386 78.7614 56 76 56Z"
                    fill="#33AFCD"
                />
                <path
                    d="M70.6667 65.6667H67.88C67.7435 65.277 67.4893 64.9393 67.1524 64.7004C66.8156 64.4615 66.4129 64.3332 66 64.3332C65.5871 64.3332 65.1844 64.4615 64.8476 64.7004C64.5107 64.9393 64.2565 65.277 64.12 65.6667H61.3333C60.98 65.6677 60.6415 65.8085 60.3917 66.0583C60.1419 66.3082 60.0011 66.6467 60 67V76.3333C60.0011 76.6866 60.1419 77.0252 60.3917 77.275C60.6415 77.5248 60.98 77.6656 61.3333 77.6667H70.6667C71.02 77.6656 71.3585 77.5248 71.6083 77.275C71.8581 77.0252 71.9989 76.6866 72 76.3333V67C71.9989 66.6467 71.8581 66.3082 71.6083 66.0583C71.3585 65.8085 71.02 65.6677 70.6667 65.6667ZM66 65.6667C66.1319 65.6667 66.2607 65.7058 66.3704 65.779C66.48 65.8523 66.5655 65.9564 66.6159 66.0782C66.6664 66.2 66.6796 66.3341 66.6539 66.4634C66.6281 66.5927 66.5646 66.7115 66.4714 66.8047C66.3782 66.898 66.2594 66.9615 66.1301 66.9872C66.0007 67.0129 65.8667 66.9997 65.7449 66.9492C65.6231 66.8988 65.5189 66.8133 65.4457 66.7037C65.3724 66.5941 65.3333 66.4652 65.3333 66.3333C65.3339 66.1567 65.4043 65.9874 65.5292 65.8625C65.6541 65.7376 65.8234 65.6672 66 65.6667ZM64.6667 69L66.5267 70.8627L68.2067 69.1827L67.3333 68.3333H70V71L69.1493 70.126L66.528 72.7493L64.6667 70.8867L62.9427 72.6093L62 71.6667L64.6667 69ZM70.6667 75.6667H61.3333V74.3333H70.6667V75.6667Z"
                    fill="white"
                />
                <g filter="url(#filter2_d_372_2219)">
                    <path
                        d="M11 96H146C147.326 96 148.598 96.5268 149.536 97.4645C150.473 98.4021 151 99.6739 151 101V126C151 127.326 150.473 128.598 149.536 129.536C148.598 130.473 147.326 131 146 131H11C9.67392 131 8.40215 130.473 7.46447 129.536C6.52678 128.598 6 127.326 6 126V101C6 99.6739 6.52678 98.4021 7.46447 97.4645C8.40215 96.5268 9.67392 96 11 96V96Z"
                        fill="white"
                    />
                </g>
                <path
                    d="M75 104H49C47.3431 104 46 105.343 46 107C46 108.657 47.3431 110 49 110H75C76.6569 110 78 108.657 78 107C78 105.343 76.6569 104 75 104Z"
                    fill="#B0E0EB"
                />
                <path
                    d="M93 117H49C47.3431 117 46 118.343 46 120C46 121.657 47.3431 123 49 123H93C94.6569 123 96 121.657 96 120C96 118.343 94.6569 117 93 117Z"
                    fill="#8AD1E2"
                />
                <path
                    d="M33 99H14C11.2386 99 9 101.239 9 104V123C9 125.761 11.2386 128 14 128H33C35.7614 128 38 125.761 38 123V104C38 101.239 35.7614 99 33 99Z"
                    fill="#33AFCD"
                />
                <path
                    d="M27.6667 108.667H24.88C24.7435 108.277 24.4893 107.939 24.1524 107.7C23.8156 107.462 23.4129 107.333 23 107.333C22.5871 107.333 22.1844 107.462 21.8476 107.7C21.5107 107.939 21.2565 108.277 21.12 108.667H18.3333C17.98 108.668 17.6415 108.809 17.3917 109.058C17.1419 109.308 17.0011 109.647 17 110V119.333C17.0011 119.687 17.1419 120.025 17.3917 120.275C17.6415 120.525 17.98 120.666 18.3333 120.667H27.6667C28.02 120.666 28.3585 120.525 28.6083 120.275C28.8581 120.025 28.9989 119.687 29 119.333V110C28.9989 109.647 28.8581 109.308 28.6083 109.058C28.3585 108.809 28.02 108.668 27.6667 108.667ZM23 108.667C23.1319 108.667 23.2607 108.706 23.3704 108.779C23.48 108.852 23.5655 108.956 23.6159 109.078C23.6664 109.2 23.6796 109.334 23.6539 109.463C23.6281 109.593 23.5646 109.711 23.4714 109.805C23.3782 109.898 23.2594 109.961 23.1301 109.987C23.0007 110.013 22.8667 110 22.7449 109.949C22.6231 109.899 22.5189 109.813 22.4457 109.704C22.3724 109.594 22.3333 109.465 22.3333 109.333C22.3339 109.157 22.4043 108.987 22.5292 108.863C22.6541 108.738 22.8234 108.667 23 108.667ZM21.6667 112L23.5267 113.863L25.2067 112.183L24.3333 111.333H27V114L26.1493 113.126L23.528 115.749L21.6667 113.887L19.9427 115.609L19 114.667L21.6667 112ZM27.6667 118.667H18.3333V117.333H27.6667V118.667Z"
                    fill="white"
                />
                <defs>
                    <filter
                        id="filter0_d_372_2219"
                        x="0"
                        y="7"
                        width="157"
                        height="47"
                        filterUnits="userSpaceOnUse"
                        color-interpolation-filters="sRGB"
                    >
                        <feFlood
                            flood-opacity="0"
                            result="BackgroundImageFix"
                        />
                        <feColorMatrix
                            in="SourceAlpha"
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha"
                        />
                        <feOffset dy="3" />
                        <feGaussianBlur stdDeviation="3" />
                        <feColorMatrix
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.161 0"
                        />
                        <feBlend
                            mode="normal"
                            in2="BackgroundImageFix"
                            result="effect1_dropShadow_372_2219"
                        />
                        <feBlend
                            mode="normal"
                            in="SourceGraphic"
                            in2="effect1_dropShadow_372_2219"
                            result="shape"
                        />
                    </filter>
                    <filter
                        id="filter1_d_372_2219"
                        x="43"
                        y="50"
                        width="157"
                        height="47"
                        filterUnits="userSpaceOnUse"
                        color-interpolation-filters="sRGB"
                    >
                        <feFlood
                            flood-opacity="0"
                            result="BackgroundImageFix"
                        />
                        <feColorMatrix
                            in="SourceAlpha"
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha"
                        />
                        <feOffset dy="3" />
                        <feGaussianBlur stdDeviation="3" />
                        <feColorMatrix
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.161 0"
                        />
                        <feBlend
                            mode="normal"
                            in2="BackgroundImageFix"
                            result="effect1_dropShadow_372_2219"
                        />
                        <feBlend
                            mode="normal"
                            in="SourceGraphic"
                            in2="effect1_dropShadow_372_2219"
                            result="shape"
                        />
                    </filter>
                    <filter
                        id="filter2_d_372_2219"
                        x="0"
                        y="93"
                        width="157"
                        height="47"
                        filterUnits="userSpaceOnUse"
                        color-interpolation-filters="sRGB"
                    >
                        <feFlood
                            flood-opacity="0"
                            result="BackgroundImageFix"
                        />
                        <feColorMatrix
                            in="SourceAlpha"
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha"
                        />
                        <feOffset dy="3" />
                        <feGaussianBlur stdDeviation="3" />
                        <feColorMatrix
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.161 0"
                        />
                        <feBlend
                            mode="normal"
                            in2="BackgroundImageFix"
                            result="effect1_dropShadow_372_2219"
                        />
                        <feBlend
                            mode="normal"
                            in="SourceGraphic"
                            in2="effect1_dropShadow_372_2219"
                            result="shape"
                        />
                    </filter>
                    <linearGradient
                        id="paint0_linear_372_2219"
                        x1="102"
                        y1="0"
                        x2="102"
                        y2="140"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stop-color="#B0E0EB" />
                        <stop offset="1" stop-color="#8AD1E2" />
                    </linearGradient>
                </defs>
            </svg>
        </>
    );
};

export default FolderIcon;
