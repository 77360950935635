import React from "react";

const TableLoading = ({ rows = 3, columns = 2 }) => {
    return (
        <>
            <table className="w-full border-collapse">
                <thead>
                    <tr className="bg-[#F9FAFB] border-y border-[#E5E7EB] text-[15px] leading-6 text-[#6B7280]">
                        <th className="px-6 py-[10px] text-left ">Status</th>
                        <th className="px-4 py-2 text-left ">Count</th>
                    </tr>
                </thead>
                <tbody className="w-full">
                    {Array.from({ length: rows }).map((_, rowIndex) => (
                        <tr
                            key={rowIndex}
                            className="bg-white border-y border-gray-200 animate-pulse last:border-non"
                        >
                            {Array.from({
                                length: columns,
                            }).map((_, colIndex) => (
                                <td key={colIndex} className="p-6">
                                    <div className="h-4 bg-gray-300 rounded w-full"></div>
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    );
};

export default TableLoading;
