import MarkIcon from "assets/Icons/icons/MarkIcon";
import ProjectIcon from "assets/Icons/icons/ProjectIcon";
import React from "react";
import useClone from "helpers/custom-hooks/clone";

const ProjectItem = ({ name, isSelected, onClick }) => {
    const { cloneSuccess } = useClone();

    return (
        <div
            className={`flex items-center justify-between py-3 px-4 cursor-pointer ${
                isSelected
                    ? "bg-[#FCF2E8] rounded-lg dark:bg-[#424E59]"
                    : "bg-transparent"
            }`}
            onClick={onClick}
        >
            <div className="flex gap-4 items-center">
                <div className="p-1 bg-[#009BC0] rounded">
                    <ProjectIcon />
                </div>
                <p className="font-medium text-[15px] leading-5 text-[#454545] dark:text-white">
                    {name}
                </p>
            </div>

            {isSelected && (
                <div className="flex items-center gap-2">
                    <MarkIcon />
                    {cloneSuccess && (
                        <span className="text-sm font-medium text-green-600 dark:text-green-400">
                            Cloned
                        </span>
                    )}
                </div>
            )}
        </div>
    );
};

export default ProjectItem;
