import React from "react";

const SearchIcon = () => {
    return (
        <>
            <svg
                width="15"
                height="16"
                viewBox="0 0 15 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M6.66667 12.1667C7.32328 12.1667 7.97346 12.0373 8.58008 11.7861C9.18671 11.5348 9.73791 11.1665 10.2022 10.7022C10.6665 10.2379 11.0348 9.68671 11.2861 9.08008C11.5373 8.47346 11.6667 7.82328 11.6667 7.16667C11.6667 6.51006 11.5373 5.85988 11.2861 5.25325C11.0348 4.64662 10.6665 4.09543 10.2022 3.63113C9.73791 3.16684 9.18671 2.79854 8.58008 2.54727C7.97346 2.296 7.32328 2.16667 6.66667 2.16667C5.34058 2.16667 4.06881 2.69345 3.13113 3.63113C2.19345 4.56881 1.66667 5.84058 1.66667 7.16667C1.66667 8.49275 2.19345 9.76452 3.13113 10.7022C4.06881 11.6399 5.34058 12.1667 6.66667 12.1667ZM6.66667 13.8333C8.43478 13.8333 10.1305 13.131 11.3807 11.8807C12.631 10.6305 13.3333 8.93478 13.3333 7.16667C13.3333 5.39856 12.631 3.70286 11.3807 2.45262C10.1305 1.20238 8.43478 0.5 6.66667 0.5C4.89856 0.5 3.20286 1.20238 1.95262 2.45262C0.702379 3.70286 0 5.39856 0 7.16667C0 8.93478 0.702379 10.6305 1.95262 11.8807C3.20286 13.131 4.89856 13.8333 6.66667 13.8333Z"
                    fill="#595959"
                />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M10.2671 10.7414C10.424 10.5857 10.6362 10.4987 10.8572 10.4995C11.0781 10.5003 11.2898 10.5888 11.4455 10.7456L14.758 14.0789C14.9094 14.2365 14.9928 14.4473 14.9903 14.6658C14.9879 14.8843 14.8996 15.0931 14.7447 15.2472C14.5897 15.4013 14.3804 15.4884 14.1619 15.4896C13.9433 15.4909 13.733 15.4062 13.5763 15.2539L10.2638 11.9206C10.108 11.7639 10.0209 11.5517 10.0215 11.3307C10.0221 11.1098 10.1105 10.8981 10.2671 10.7423V10.7414Z"
                    fill="#595959"
                />
            </svg>
        </>
    );
};

export default SearchIcon;

export const DarkSearchIcon = () => {
    return (
        <>
            <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M7.16667 12.1667C7.82328 12.1667 8.47346 12.0373 9.08008 11.7861C9.68671 11.5348 10.2379 11.1665 10.7022 10.7022C11.1665 10.2379 11.5348 9.68671 11.7861 9.08008C12.0373 8.47346 12.1667 7.82328 12.1667 7.16667C12.1667 6.51006 12.0373 5.85988 11.7861 5.25325C11.5348 4.64662 11.1665 4.09543 10.7022 3.63113C10.2379 3.16684 9.68671 2.79854 9.08008 2.54727C8.47346 2.296 7.82328 2.16667 7.16667 2.16667C5.84058 2.16667 4.56881 2.69345 3.63113 3.63113C2.69345 4.56881 2.16667 5.84058 2.16667 7.16667C2.16667 8.49275 2.69345 9.76452 3.63113 10.7022C4.56881 11.6399 5.84058 12.1667 7.16667 12.1667ZM7.16667 13.8333C8.93478 13.8333 10.6305 13.131 11.8807 11.8807C13.131 10.6305 13.8333 8.93478 13.8333 7.16667C13.8333 5.39856 13.131 3.70286 11.8807 2.45262C10.6305 1.20238 8.93478 0.5 7.16667 0.5C5.39856 0.5 3.70286 1.20238 2.45262 2.45262C1.20238 3.70286 0.5 5.39856 0.5 7.16667C0.5 8.93478 1.20238 10.6305 2.45262 11.8807C3.70286 13.131 5.39856 13.8333 7.16667 13.8333Z"
                    fill="#FFFFFF"
                />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M10.7671 10.7417C10.924 10.586 11.1362 10.499 11.3572 10.4998C11.5781 10.5005 11.7898 10.5891 11.9455 10.7458L15.258 14.0792C15.4094 14.2368 15.4928 14.4475 15.4903 14.666C15.4879 14.8846 15.3996 15.0934 15.2447 15.2475C15.0897 15.4015 14.8804 15.4886 14.6619 15.4899C14.4433 15.4911 14.233 15.4065 14.0763 15.2542L10.7638 11.9208C10.608 11.7641 10.5209 11.5519 10.5215 11.331C10.5221 11.11 10.6105 10.8983 10.7671 10.7425V10.7417Z"
                    fill="#FFFFFF"
                />
            </svg>
        </>
    );
};
