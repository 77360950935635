import React, { useCallback, useEffect, useState } from "react";
import JiraServiceDeskCounts from "components/jira-service-desk-counts/jira-service-desk-counts";
import SchemeCounts from "components/scheme-counts/scheme-counts";
import JiraSoftwareCounts from "components/jira-software-counts/jira-software-counts";
import { useDispatch, useSelector } from "react-redux";
import { cloudCounterTabs } from "constant/cloud-counter";
import Axios from "axios";
import { toast } from "react-toastify";
import {
    updateJWT,
    updateSchemeData,
    updateServiceDeskData,
    updateSoftwareData,
} from "store/cloud-counter/CloudCounterSlice";
import Exports from "components/exports/exports";
import EmptyState from "components/EmptyState";

const MainCloudCounter = () => {
    const [activeTab, setActiveTab] = useState("software");
    const dispatch = useDispatch();
    const tokenVal = useSelector((state) => state.project.bodyToken);
    const { software_counts, service_desk_counts, scheme_counts } = useSelector(
        (state) => state.cloudCounter
    );

    const requestResources = (token) => {
        const resources = ["scheme", "software", "servicedesk"];
        const requests = resources.map(async (resource) => {
            try {
                const res = await Axios.post(`/jira/${resource}/`, {
                    token: token,
                });
                const resourceData = res.data;
                return {
                    response: resourceData,
                    resource,
                };
            } catch (err) {
                toast.error(
                    `Unable to get ${resource} data at the moment. Kindly try again by refreshing the page`,
                    { appearance: "error" }
                );
            }
        });
        Promise.all(requests).then((results) => {
            results.forEach((result) => {
                if (result === undefined) return;
                const { previous, ...data } = result.response;
                let id = 0;

                const payload = [
                    { data, date: new Date().toISOString(), id: id++ },
                    ...previous.map((data) => ({
                        ...data,
                        id: id++,
                    })),
                ];
                console.log(result);

                switch (result.resource) {
                    case "software":
                        dispatch(updateSoftwareData(payload));
                        break;
                    case "servicedesk":
                        dispatch(updateServiceDeskData(payload));
                        break;
                    case "scheme":
                        dispatch(updateSchemeData(payload));
                        break;
                    default:
                        break;
                }
            });
        });
    };

    useEffect(() => {
        const location = window.location;
        const searchObj = new URLSearchParams(location.search.substr(1));
        const jwt = tokenVal.token;
        const lic = searchObj.get("lic");
        console.log(`APP_MODE is ${process.env.MODE} `);
        if (
            process.env.REACT_APP_MODE === "development" ||
            process.env.REACT_APP_NODE_ENV === "dev"
        ) {
            dispatch(updateJWT(jwt));
            requestResources(jwt);
        } else {
            if (!lic || lic.toLowerCase() !== "active") {
                toast.error(
                    `Unable to verify your license. Kindly reach out to your site admin`,
                    { appearance: "error" }
                );
            } else {
                dispatch(updateJWT(jwt));
                requestResources(jwt);
            }
        }
    }, []);

    const getViewData = useCallback(() => {
        switch (activeTab) {
            case "software":
                return {
                    data: software_counts || [],
                    title: "Jira Software",
                };
            case "servicedesk":
                return {
                    data: service_desk_counts || [],
                    title: "Jira Service Management",
                };
            case "scheme":
                return { data: scheme_counts || [], title: "Scheme" };
            default:
                return { data: [], title: "" };
        }
    }, [activeTab]);

    const componentMapper = () => {
        switch (activeTab) {
            case "scheme":
                return <SchemeCounts />;

            case "management":
                return <JiraServiceDeskCounts />;
        }
    };

    return (
        <>
            {software_counts.length === 1 ? (
                <div className="mt-[125px]">
                    <EmptyState />
                </div>
            ) : (
                <div className="pt-6">
                    <div className="flex justify-between w-full items-center pl-10">
                        <p className="font-medium text-[20px] leading-[30px] text-[#595959] dark:text-white">
                            Cloud Counter
                        </p>
                        <Exports info={getViewData()} />
                    </div>

                    <div className="mt-8">
                        <div className="flex border-b border-[#D9D9D9] dark:border-[#424E59] pl-16 gap-1">
                            {cloudCounterTabs.map((tab) => (
                                <button
                                    key={tab.id}
                                    onClick={() => setActiveTab(tab.id)}
                                    className={`py-[10px] px-4 text-[15px] leading font-medium border-t border-x border-[#D9D9D9] dark:border-[#424E59] rounded-t-lg dark:text-white ${
                                        activeTab === tab.id
                                            ? "text-[#262626] "
                                            : "text-[#595959] bg-[#F5F5F5] dark:bg-[#424E59]"
                                    }`}
                                >
                                    {tab.title}
                                </button>
                            ))}
                        </div>
                        <div className="pl-10 mt-10">
                            {activeTab === "software" ? (
                                <JiraSoftwareCounts />
                            ) : (
                                componentMapper()
                            )}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default MainCloudCounter;
