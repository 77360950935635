import FolderIcon from "assets/Icons/icons/FolderIcon";
import React from "react";

const EmptyState = () => {
    return (
        <div className="flex flex-col items-center">
            <FolderIcon />
            <div className="mt-10 mb-4 text-center">
                <p className="text-[20px] text-[#262626] dark:text-white font-bold leading-6">
                    No Projects Selected
                </p>
                <p className="text-[16px] text-[#595959] dark:text-white leading-6 mt-4">
                    Select a project from the available projects to start
                    managing <br /> tasks, visualizing data, and syncing
                    seamlessly.
                </p>
            </div>

            <button className="leading-6 font-semibold text-[#E37915] rounded-lg px-[18px] py-[10px]">
                Learn More
            </button>
        </div>
    );
};

export default EmptyState;
