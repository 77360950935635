import Axios from "axios";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { projectsLoading, saveProjects, setCurrentProject } from "store/sc";
import { useLocation } from "react-router-dom";
import queryString from "query-string";

function useProjects() {
    const [loading, setloading] = useState(false);
    const [projectLoading, setProjectLoading] = useState(false);
    const [error, setError] = useState(false);
    const projects = useSelector((state) => state.project.projects);
    const dispatch = useDispatch();
    const location = useLocation();

    async function getProjects(values) {
        setloading(true);
        dispatch(projectsLoading(true));

        try {
            const result = await Axios.post(`/projects`, values);
            if (result) {
                dispatch(saveProjects(result.data.data));
                setloading(false);
                dispatch(projectsLoading(false));
            }
        } catch (error) {
            setloading(false);
            dispatch(projectsLoading(false));
            setError(true);
        }
    }

    async function getProject(key, values) {
        setProjectLoading(true);
        try {
            const result = await Axios.post(`/projects/${key}`, values);
            if (result) {
                setProjectLoading(false);
                dispatch(setCurrentProject(result.data.data));
            }
        } catch (error) {
            console.log("error", error);
            setProjectLoading(false);
            setError(true);
        }
    }

    async function projectSessionInit() {
        try {
            const query = location.search;
            const values = queryString.parse(query);
            if (values.jwt) {
                return getProjects({
                    token: values.jwt,
                });
            }
        } catch (err) {
            console.error("Error initializing project session:", err);
        }
    }

    return {
        getProjects,
        getProject,
        loading,
        projects,
        error,
        projectSessionInit,
        projectLoading,
    };
}

export default useProjects;
