import React from "react";
import HeadingSubHead from "./headingSubHead";
import Joyride from "react-joyride";
import { LiaTimesSolid } from "react-icons/lia";

const TourGuideSteps = ({ joyrideStart, setJoyrideStart }) => {
    // Custom Tooltip Component
    const CustomTooltip = ({
        continuous,
        index,
        step,
        size,
        backProps,
        primaryProps,
        tooltipProps,
        skipProps,
    }) => {
        return (
            <div
                {...tooltipProps}
                style={{
                    backgroundColor: "#ffffff",
                    borderRadius: "8px",
                    maxWidth: "400px",
                    textAlign: "left",
                    boxShadow: "0px 32px 64px -12px #10182824",
                }}
            >
                {/* Tooltip Content */}
                <div className="px-6 pt-6 border-b border-[#bfbfbf] pb-3 flex justify-between items-start">
                    {step.content}
                    <button {...skipProps} className="cursor-pointer">
                        <LiaTimesSolid size={20} className="text-[#595959]" />
                    </button>
                </div>
                {/* Bottom Section */}
                <div className="flex justify-between items-center pb-3 px-6 mt-3">
                    {/* Step Indicator (Bottom Left) */}
                    <div style={{ fontSize: "14px", color: "#454545" }}>
                        {index + 1} of {size}
                    </div>

                    {/* Buttons (Bottom Right) */}
                    <div>
                        {index > 0 && (
                            <button
                                {...backProps}
                                style={{
                                    boxShadow: "0px -2px 4px 0px #ffffff33",
                                }}
                                className="bg-transparent text-[#595959] border border-[#d9d9d9] py-2 px-4 text-sm font-semibold rounded-lg mr-3"
                            >
                                Previous
                            </button>
                        )}
                        <button
                            {...primaryProps}
                            style={{
                                boxShadow: "0px -2px 4px 0px #ffffff33",
                            }}
                            className="bg-[#E37915] border-none text-[white] py-2 px-3 text-sm rounded cursor-pointer"
                        >
                            {index === size - 1 ? "Finish" : "Next"}
                        </button>
                    </div>
                </div>
            </div>
        );
    };

    // Define your tour steps
    const steps = [
        {
            target: ".project-section",
            content: (
                <div className="text-left">
                    <HeadingSubHead
                        heading={"Streamlined Plugin Design"}
                        fontStyling="small"
                        subHeading={
                            "The Export & Count for Cloud plugin is now easier and faster to use. The redesigned layout offers quick access to projects and features."
                        }
                    />
                </div>
            ),
            disableBeacon: true,
            placement: "right",
        },
        {
            target: ".project-list",
            content: (
                <div className="text-left">
                    <HeadingSubHead
                        heading={"Streamlined Plugin Design"}
                        fontStyling="small"
                        subHeading={
                            "The Export & Count for Cloud plugin is now easier and faster to use. The redesigned layout offers quick access to projects and features."
                        }
                    />
                </div>
            ),
            disableBeacon: true,
        },
        {
            target: ".export-cloudcounter",
            content: (
                <div className="text-left">
                    <HeadingSubHead
                        heading={
                            "Faster Project Access & Enhanced Productivity 🚀"
                        }
                        fontStyling="small"
                        subHeading={
                            "Export and Count for Cloud now offers a more intuitive design for quick, seamless project access, improving efficiency and productivity."
                        }
                    />
                </div>
            ),
            disableBeacon: true,
            placement: "bottom",
        },
        {
            target: ".theme",
            content: (
                <div className="text-left">
                    <HeadingSubHead
                        heading={
                            "Light & Dark Theme: Customize Your Experience 🌙☀️"
                        }
                        fontStyling="small"
                        subHeading={
                            "Switch seamlessly between light and dark modes for a personalized and comfortable visual experience. Choose what suits you best!"
                        }
                    />
                </div>
            ),
            disableBeacon: true,
            placement: "bottom",
        },
        {
            target: ".export",
            content: (
                <div className="text-left">
                    <HeadingSubHead
                        heading={
                            "Enhanced Export: Effortless Data Management 📊🚀"
                        }
                        fontStyling="small"
                        subHeading={
                            "Export project data including issues & attachments in CSV, JSON, or PDF for seamless processing. Select what you need, download instantly, and store it in your preferred format for analysis."
                        }
                    />
                </div>
            ),
            disableBeacon: true,
            placement: "bottom",
        },
        {
            target: ".clone",
            content: (
                <div className="text-left">
                    <HeadingSubHead
                        heading={
                            "Enhanced Cloning: Quick & Seamless Project Duplication 🔄🚀"
                        }
                        fontStyling="small"
                        subHeading={
                            "Admins can now duplicate projects within the same Jira instance effortlessly. Create similar projects with different configurations in seconds—just drag & drop or click to clone!"
                        }
                    />
                </div>
            ),
            disableBeacon: true,
            placement: "bottom",
        },
    ];

    const handleJoyrideCallback = (data) => {
        const { status } = data;
        const finishedStatuses = ["finished", "skipped"];

        if (finishedStatuses.includes(status)) {
            setJoyrideStart(false);
        }
    };

    return (
        <div>
            <Joyride
                steps={steps}
                run={joyrideStart}
                continuous={true} // Automatically proceed to the next step
                showProgress={false} // Disable default progress indicator
                showSkipButton={false} // Disable default skip button
                tooltipComponent={CustomTooltip} // Use custom tooltip
                spotlightPadding={0}
                callback={handleJoyrideCallback}
                styles={{
                    spotlight: {
                        border: "2px solid #E37915", // Add border to the spotlight
                        borderRadius: "4px", // Optional: Add border radius to the spotlight
                    },
                }}
            />
        </div>
    );
};

export default TourGuideSteps;
