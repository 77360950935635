import CloseIcon, { DarkCLoseIcon } from "assets/Icons/icons/CloseIcon";
import { motion } from "framer-motion";
import { useIsDarkTheme } from "helpers/darktheme";

const SideDrawer = ({
    isOpen,
    onClose,
    children,
    width = "w-[447px]",
    buttonLabel = "Close",
    onButtonClick,
    title = "",
    subtitle = "",
}) => {
    const isDarkTheme = useIsDarkTheme();

    return (
        <>
            {/* Backdrop */}
            {isOpen && (
                <div
                    className="fixed inset-0 bg-black bg-opacity-50 z-40"
                    onClick={onClose}
                ></div>
            )}

            {/* Drawer */}
            <motion.div
                initial={{ x: "100%" }}
                animate={{ x: isOpen ? 0 : "100%" }}
                transition={{ type: "spring", stiffness: 300, damping: 30 }}
                className={`fixed top-0 right-0 h-full bg-white dark:bg-[#0D1822] dark:border-[#424E59] border shadow-xl z-50 flex flex-col ${width}`}
            >
                {/* Header */}
                <div className="py-4 px-6 border-b flex justify-end items-center">
                    <button
                        className="p-2 rounded-lg border border-[#D9D9D9] dark:border-[#424E59] dark:bg-[#424E59]"
                        onClick={onClose}
                    >
                        {isDarkTheme ? <CloseIcon /> : <DarkCLoseIcon />}
                    </button>
                </div>

                {/* Drawer Content */}

                <div className="flex-1 px-6 mt-5 overflow-y-auto">
                    <div className="space-y-2">
                        <h1 className="font-medium text-[24px] leading-6 text-[#262626] dark:text-white">
                            {title}
                        </h1>
                        <p className="font-medium text-[15px] leading-6 dark:text-white">
                            {subtitle}
                        </p>
                    </div>
                    <div className="mt-6">{children}</div>
                </div>

                {/* Sticky Bottom Button */}
                <div className="px-6 py-4 border-t dark:border-[#424E59]">
                    <button
                        onClick={onButtonClick || onClose}
                        className="w-full py-[10px] bg-[#E37915] text-white rounded-lg font-semibold leading-6 text-[16px]"
                    >
                        {buttonLabel}
                    </button>
                </div>
            </motion.div>
        </>
    );
};

export default SideDrawer;
