import React from "react";

const LightModeIcon = () => {
    return (
        <>
            <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M2.29169 9.99999C2.29169 12.0444 3.10381 14.005 4.54941 15.4506C5.995 16.8962 7.95564 17.7083 10 17.7083V2.29166C7.95564 2.29166 5.995 3.10378 4.54941 4.54938C3.10381 5.99497 2.29169 7.95561 2.29169 9.99999Z"
                    fill="white"
                />
                <path
                    d="M10 17.7083C12.0444 17.7083 14.005 16.8962 15.4506 15.4506C16.8962 14.005 17.7084 12.0444 17.7084 9.99999C17.7084 7.95561 16.8962 5.99497 15.4506 4.54938C14.005 3.10378 12.0444 2.29166 10 2.29166M10 17.7083C7.95564 17.7083 5.995 16.8962 4.54941 15.4506C3.10381 14.005 2.29169 12.0444 2.29169 9.99999C2.29169 7.95561 3.10381 5.99497 4.54941 4.54938C5.995 3.10378 7.95564 2.29166 10 2.29166M10 17.7083V2.29166"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </svg>
        </>
    );
};

export default LightModeIcon;
