import React from "react";

const HeadingSubHead = ({
    heading,
    subHeading,
    fontStyling = "large",
    showSubHead = true,
    showHeading = true,
}) => {
    return (
        <>
            {showHeading && (
                <h5
                    className={`text-[#262626] font-semibold mb-2 ${
                        fontStyling === "large" ? "text-xl" : "text-base"
                    }`}
                >
                    {heading}
                </h5>
            )}
            {showSubHead && (
                <p
                    className={`  mb-2 ${
                        fontStyling === "large"
                            ? "text-[15px] text-[#454545] leading-6"
                            : "text-sm text-[#262626]"
                    }`}
                >
                    {subHeading}
                </p>
            )}
        </>
    );
};

export default HeadingSubHead;
