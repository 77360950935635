import React from "react";

const ArrowDown = ({ color = "#6B7280" }) => {
    return (
        <>
            <svg
                width="12"
                height="8"
                viewBox="0 0 12 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M10.8842 1.11623C11.0003 1.23231 11.0924 1.37012 11.1552 1.5218C11.2181 1.67348 11.2504 1.83605 11.2504 2.00023C11.2504 2.16441 11.2181 2.32699 11.1552 2.47866C11.0924 2.63034 11.0003 2.76816 10.8842 2.88423L6.00016 7.76823L1.11617 2.88423C0.881714 2.64978 0.75 2.3318 0.75 2.00023C0.75 1.83606 0.782337 1.67349 0.845163 1.52182C0.90799 1.37014 1.00008 1.23232 1.11617 1.11623C1.23225 1.00014 1.37007 0.908058 1.52175 0.845231C1.67342 0.782404 1.83599 0.750068 2.00016 0.750068C2.33173 0.750068 2.64971 0.881781 2.88416 1.11623L6.00016 4.23223L9.11617 1.11623C9.23224 1.00012 9.37006 0.90802 9.52173 0.845181C9.67341 0.782342 9.83599 0.75 10.0002 0.75C10.1643 0.75 10.3269 0.782342 10.4786 0.845181C10.6303 0.90802 10.7681 1.00012 10.8842 1.11623Z"
                    fill={color}
                />
            </svg>
        </>
    );
};

export default ArrowDown;
