import ProjectContainer from "components/common/ProjectContainer";
import React from "react";
import { useSelector } from "react-redux";

const CloneSideDrawer = () => {
    const current = useSelector((state) => state.project.currentProject);

    return (
        <>
            <ProjectContainer project={current} />
        </>
    );
};

export default CloneSideDrawer;
