import React from "react";

const DarkModeIcon = () => {
    return (
        <>
            <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M1.29199 8.99984C1.29199 11.0442 2.10412 13.0049 3.54971 14.4505C4.9953 15.896 6.95595 16.7082 9.00033 16.7082V1.2915C6.95595 1.2915 4.9953 2.10363 3.54971 3.54922C2.10412 4.99482 1.29199 6.95546 1.29199 8.99984Z"
                    fill="#595959"
                />
                <path
                    d="M9.00033 16.7082C11.0447 16.7082 13.0053 15.896 14.4509 14.4505C15.8965 13.0049 16.7087 11.0442 16.7087 8.99984C16.7087 6.95546 15.8965 4.99482 14.4509 3.54922C13.0053 2.10363 11.0447 1.2915 9.00033 1.2915M9.00033 16.7082C6.95595 16.7082 4.9953 15.896 3.54971 14.4505C2.10412 13.0049 1.29199 11.0442 1.29199 8.99984C1.29199 6.95546 2.10412 4.99482 3.54971 3.54922C4.9953 2.10363 6.95595 1.2915 9.00033 1.2915M9.00033 16.7082V1.2915"
                    stroke="#595959"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </svg>
        </>
    );
};

export default DarkModeIcon;
