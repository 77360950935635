import ProjectItem from "components/common/ProjectItem";
import React from "react";
import useProjects from "helpers/custom-hooks/projects";
import useVisualize from "helpers/custom-hooks/visualize";
import { useSelector, useDispatch } from "react-redux";
import { setIsVisualizing } from "../../store/sc";

const ProjectList = ({ projects, selectedProject, setSelectedProject }) => {
    const { getProject } = useProjects();
    const { visualize } = useVisualize();
    const bodyToken = useSelector((state) => state.project.bodyToken);
    const dispatch = useDispatch();

    async function handleSelectedProject(project) {
        dispatch(setIsVisualizing(true));
        setSelectedProject(project?.name);
        try {
            getProject(project.key, bodyToken);
            visualize(project.key);
            dispatch(setIsVisualizing(false));
        } catch (error) {
            dispatch(setIsVisualizing(false));
            throw new Error("An error occurred:", error);
        }
    }

    return (
        <div className="space-y-[6px] flex-1 overflow-y-auto px-4 pb-4 mt-4 dark:bg-[#0D182]">
            {projects.map((project, index) => (
                <ProjectItem
                    key={index}
                    name={project?.name}
                    isSelected={selectedProject === project?.name}
                    onClick={() => handleSelectedProject(project)}
                />
            ))}
        </div>
    );
};

export default ProjectList;
