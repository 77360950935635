import React, { useState, useEffect, useMemo, useCallback } from "react";
import {
    titleCase,
    sortObjectKeys,
    formatDate,
    extractChartData,
} from "../../utils/util.ts";
import Table from "../Table/table";
import PreviousCounts from "../previous-counts/previous-counts";
import BarChartView from "../bar-chart/index.jsx";
import { useSelector } from "react-redux";

const JiraServiceDeskCounts = (props) => {
    const [serviceDeskCountsItems, setServiceDeskCountsItems] = useState([]);
    const [currentViewId, setCurrentViewId] = useState(1);

    const service_desk_counts = useSelector(
        (state) => state.cloudCounter.service_desk_counts
    );

    const { chartData } = useMemo(
        () => extractChartData(service_desk_counts),
        [service_desk_counts]
    );

    const sortedList = useMemo(
        () => sortObjectKeys(service_desk_counts[0]?.data),
        [service_desk_counts]
    );

    const currentCount = service_desk_counts[0]?.data;
    const previousCount = useMemo(
        () =>
            service_desk_counts.find((info) => info.id === currentViewId)
                ?.data || {},
        [currentViewId, service_desk_counts]
    );

    const date = useMemo(() => {
        const selectedDate = service_desk_counts.find(
            (info) => info.id === currentViewId
        )?.date;
        return formatDate(selectedDate, "LL");
    }, [currentViewId, service_desk_counts]);

    const updateDataTable = useCallback(() => {
        const items = sortedList.map((row, id) => {
            const title = titleCase(row[0]);
            const currentValue = currentCount[row[0]];
            const previousValue = previousCount[row[0]] ?? 0;
            const percentageDiff = previousValue
                ? (
                      ((currentValue - previousValue) / previousValue) *
                      100
                  ).toFixed(2)
                : "N/A";

            return {
                id,
                title,
                currentCount: currentValue,
                previousCount: previousValue,
                percentageDiff: percentageDiff + "%",
            };
        });
        setServiceDeskCountsItems(items);
    }, [currentCount, previousCount, sortedList]);

    const previousData = useMemo(
        () => service_desk_counts.slice(2),
        [service_desk_counts]
    );

    useEffect(() => {
        updateDataTable();
    }, [currentViewId, updateDataTable]);

    return (
        <>
            <BarChartView
                datas={chartData}
                title="Jira Service Management"
                date={date}
            />
            <div className="border border-[#D9D9D9] dark:border-[#424E59] shadow-sm rounded-lg mt-6">
                <Table data={serviceDeskCountsItems} />
            </div>
            {/* <div className="mt-5">
                <PreviousCounts
                    history={previousData}
                    setCurrentViewId={setCurrentViewId}
                    currentViewId={currentViewId}
                />
            </div> */}
        </>
    );
};

export default JiraServiceDeskCounts;
