import React from "react";

const MoonIcon = () => {
    return (
        <>
            <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <mask
                    id="mask0_906_10655"
                    maskUnits="userSpaceOnUse"
                    x="1"
                    y="1"
                    width="18"
                    height="18"
                >
                    <path
                        d="M10.0012 1.66663L12.197 3.86246H16.1375V7.80246L18.3333 9.99829L16.1375 12.1975V16.1375H12.1975L10.0016 18.3333L7.80246 16.1375H3.86246V12.1975L1.66663 9.99871L3.86246 7.80288V3.86246H7.80246L10.0012 1.66663Z"
                        fill="white"
                        stroke="white"
                        stroke-width="1.33333"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                    <path
                        d="M11.2499 7.08325C11.2499 10.4166 9.16659 10.8333 7.08325 10.8333C7.08325 12.4999 9.79159 14.1666 12.0833 12.4999C14.3749 10.8333 12.9166 7.08325 11.2499 7.08325Z"
                        fill="black"
                        stroke="black"
                        stroke-width="1.33333"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                </mask>
                <g mask="url(#mask0_906_10655)">
                    <path d="M0 0H20V20H0V0Z" fill="#595959" />
                </g>
            </svg>
        </>
    );
};

export default MoonIcon;

export const DarkMoonIcon = () => {
    return (
        <>
            <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <mask
                    id="mask0_1233_17157"
                    maskUnits="userSpaceOnUse"
                    x="1"
                    y="1"
                    width="18"
                    height="18"
                >
                    <path
                        d="M10.0006 1.66699L12.1964 3.86283H16.1368V7.80283L18.3327 9.99866L16.1368 12.1978V16.1378H12.1968L10.001 18.3337L7.80185 16.1378H3.86185V12.1978L1.66602 9.99908L3.86185 7.80324V3.86283H7.80185L10.0006 1.66699Z"
                        fill="#FCFCFC"
                        stroke="white"
                        stroke-width="1.33333"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                    <path
                        d="M11.2487 7.08398C11.2487 10.4173 9.16536 10.834 7.08203 10.834C7.08203 12.5007 9.79037 14.1673 12.082 12.5007C14.3737 10.834 12.9154 7.08398 11.2487 7.08398Z"
                        fill="black"
                        stroke="black"
                        stroke-width="1.33333"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                </mask>
                <g mask="url(#mask0_1233_17157)">
                    <path d="M0 0H20V20H0V0Z" fill="#FCFCFC" />
                </g>
            </svg>
        </>
    );
};
