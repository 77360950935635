import React, { useEffect, useState } from "react";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faSpinner, faTimes } from "@fortawesome/free-solid-svg-icons";
import { useLocation } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import { useDispatch } from "react-redux";
import "./App.css";
import { ThemeProvider, createGlobalStyle } from "styled-components";
import localforage from "localforage";
import useProjects from "helpers/custom-hooks/projects";
import { toggleModal, setBodyToken } from "store/sc";
import queryString from "query-string";
import ErrorBoundary from "helpers/errorBoundary";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import Router from "router";
import useSelectedType from "helpers/custom-hooks/selectedType";

library.add(faSpinner, faTimes);
localforage.config({
    name: "myApp",
    version: 1.0,
    storeName: "single_project",
    description: "single_project",
});

const theme = {
    colors: {
        primary_dark_blue: "#1D1554",
        secondary_light_blue: "#2B11E1",
        secondary_dark_blue: "#374DD0",
    },
};

const GlobalStyle = createGlobalStyle`
body{
  background:#1C1454;
  background: ${(props) => (props.mode ? "#1C1454" : "#fff")};
  height:100vh;
}
`;

const App = () => {
    const location = useLocation();
    const isPath = location.pathname.includes("/cloud-counter");
    const { projectSessionInit } = useProjects();
    const { getDashBoardData } = useSelectedType();

    useEffect(() => {
        const baseURL = `https://${process.env.REACT_APP_API_URL}/v1`;
        axios.defaults.baseURL = baseURL;
    }, [isPath]);

    const dispatch = useDispatch();
    const [toggle, setToggle] = useState(false);

    useEffect(() => {
        projectSessionInit();
        const query = location.search;
        const values = queryString.parse(query);

        if (values.jwt) {
            dispatch(toggleModal(false));
            dispatch(setBodyToken({ token: values.jwt }));
            getDashBoardData(values.jwt);
        } else {
            dispatch(toggleModal(true));
        }
    }, []);

    return (
        <ErrorBoundary>
            <SnackbarProvider
                maxSnack={1}
                preventDuplicate
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            >
                <GlobalStyle mode={toggle} />
                <ThemeProvider theme={theme}>
                    <ToastContainer />
                    <Router />
                </ThemeProvider>
            </SnackbarProvider>
        </ErrorBoundary>
    );
};

export default App;
