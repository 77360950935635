import React from "react";
import SearchIcon, { DarkSearchIcon } from "assets/Icons/icons/SearchIcon";
import { useIsDarkTheme } from "helpers/darktheme";

const SearchInput = ({ searchQuery, setSearchQuery }) => {
    const isDarkTheme = useIsDarkTheme();

    return (
        <div className="relative">
            <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400">
                {isDarkTheme ? <SearchIcon /> : <DarkSearchIcon />}
            </span>
            <input
                type="text"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="w-full py-[10px] pl-10 pr-2 border rounded-lg shadow-sm placeholder:text-[#8C8C8C] placeholder:text-[15px] font-medium leading-6 dark:bg-[#132230] dark:border-[#424E59] dark:placeholder:text-[#9299A0]"
                placeholder="Search projects"
            />
            <span className="absolute right-3 top-[12px] text-gray-500">
                <span className="border border-[#E5E7EB] rounded text-[#6B7280] dark:text-white font-medium text-[12px] leading-[14px] p-1">
                    ⌘K
                </span>
            </span>
        </div>
    );
};

export default SearchInput;
