import { useState } from "react";
import search from "../../assets/Homepage Assets/redesign/search.svg";
import { IoCheckmark } from "react-icons/io5";

const Sort = ({ prop, onclick, data, onSort }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [option, SetOption] = useState("Alphabetically");
    const options = ["Ascending", "Descending", "Alphabetically"];
    const [activeIndex, setActiveIndex] = useState(null);

    const handleSelect = (optionSelect) => {
        SetOption(optionSelect);
        setIsOpen(!isOpen);
        let sortedData;

        switch (optionSelect) {
            case "Ascending":
                sortedData = [...data].sort((a, b) => a?.number - b?.number);
                break;
            case "Descending":
                sortedData = [...data].sort((a, b) => b.number - a.number);
                break;
            case "Alphabetically":
                sortedData = [...data].sort((a, b) =>
                    a?.status.localeCompare(b?.status)
                );
                break;
            default:
                return;
        }
        onSort(sortedData);
    };

    const handleOpen = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="relative">
            <div
                className="flex items-center gap-x-2 px-3 py-2 bg-white dark:bg-[#424E59] border border-[#d9d9d9] dark:border-[#616B74] rounded-lg cursor-pointer"
                onClick={handleOpen}
            >
                <p className="">
                    <span className="font-medium text-sm text-[#595959] dark:text-white">
                        Sort by:
                    </span>{" "}
                    <span className="font-medium text-[15px] leading-5 text-[#262626] dark:text-white">
                        {option}
                    </span>
                </p>
                <img src={search} width={20} height={20} alt="" />
            </div>

            {isOpen && (
                <ul className="absolute top-full left-0 w-full bg-[white] dark:bg-[#111F2C] border border-[#ccc] dark:border-[#424E59] dark:text-white rounded-md shadow-md z-10 list-[none] mt-2">
                    {options.map((option, index) => (
                        <div
                            className="group flex items-center gap-2 py-3 px-4 cursor-pointer hover:bg-[#f4f4f4] dark:hover:bg-[#424E59]"
                            onClick={() => setActiveIndex(index)}
                        >
                            <div>
                                <IoCheckmark
                                    className={`text-[#595959] ${
                                        activeIndex === index
                                            ? "opacity-100"
                                            : "opacity-0 group-hover:opacity-100"
                                    } transition-opacity duration-300`}
                                />
                            </div>
                            <li
                                key={index}
                                className=""
                                onClick={() => handleSelect(option)}
                            >
                                {option}
                            </li>
                        </div>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default Sort;
