import React, { useState } from "react";
import Axios from "axios";
import "./attachments.scss";
import { useSelector } from "react-redux";
import AttachmentIcon from "assets/Icons/icons/AttachmentIcon";

const Attachments = () => {
    const jwt = useSelector((state) => state.cloudCounter.jwt);
    const [responseData, setResponseData] = useState({});
    const [loading, setIsLoading] = useState(false);

    const attachmentRequest = () => {
        setIsLoading(true);
        Axios.post("/jira/attachment/", { token: jwt }).then((res) => {
            const taskId = res.data.task_id;
            const attSocket = new WebSocket(
                `wss://${process.env.REACT_APP_API_URL}/jira/attachment/` +
                    taskId +
                    "/"
            );
            attSocket.onmessage = (e) => {
                const data = JSON.parse(e.data);
                const attachmentData = JSON.parse(data.message);
                setResponseData(attachmentData.attachments);
                setIsLoading(false);
            };
        });
    };

    return (
        <>
            <div className="border border-[#D9D9D9] dark:border-[#424E59] bg-[#F5F5F5] dark:bg-[#0D1822] py-[18px] px-6 flex items-center gap-5 rounded-lg mt-6">
                {loading ? (
                    <div className="text-[#8C8C8C] text-[15px]">
                        Attachments is in Progres....
                    </div>
                ) : (
                    <>
                        <div
                            onClick={attachmentRequest}
                            className="bg-[#009BC0] p-[10px] rounded-[4px]"
                        >
                            <AttachmentIcon />
                        </div>
                        <div className="font-medium space-y-1 text-[16px]">
                            <p className="text-[#262626] dark:text-white leading-4">
                                Attachments
                            </p>
                            <div className="flex items-center text-[#8C8C8C] text-[15px]">
                                <p>{responseData.total_size} mb</p>
                                <div className="w-[1.5px] h-[1.5px] rounded-full border-[1.5px] border-[#8C8C8C] mx-[9.3px]" />
                                <p>{responseData.count} counts</p>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </>
    );
};

export default Attachments;
