import moment from "moment";
import { jsPDF, CellConfig, TableConfig } from "jspdf";

export const titleCase = (str: string) => {
    if (typeof str !== "string") return "";
    return str
        ?.toLowerCase()
        .split(" ")
        .map(function (word) {
            return word.charAt(0).toUpperCase() + word.slice(1);
        })
        .join(" ");
};

const baseColors = [
    { hue: 220, saturation: 70, lightness: 50 },
    { hue: 0, saturation: 70, lightness: 50 },
    { hue: 45, saturation: 85, lightness: 55 },
    { hue: 160, saturation: 70, lightness: 45 },
    { hue: 270, saturation: 60, lightness: 50 },
    { hue: 30, saturation: 85, lightness: 55 },
];

const generateColor = (index: number): string => {
    const baseColor = baseColors[index % baseColors.length];
    const lightness = Math.max(30, baseColor.lightness - (index % 5) * 5);
    return `hsl(${baseColor.hue}, ${baseColor.saturation}%, ${lightness}%)`;
};

export const extractChartData = (data: any) => {
    if (!data || data.length === 0 || !data[0].data) {
        return {
            labels: [],
            datasets: [],
            chartData: { labels: [], datasets: [] },
        };
    }

    const labels = Object?.keys(data[0]?.data);

    const datasets = data.map((entry: any, index: number) => ({
        label: `Date ${new Date(entry.date).toLocaleDateString()}`,
        data: labels.map((key) => entry.data[key as keyof typeof entry.data]),
        backgroundColor: generateColor(index),
        barPercentage: 0.9,
        categoryPercentage: 0.7,
    }));

    const chartData = {
        labels,
        datasets,
    };

    return { labels, datasets, chartData };
};

export const sortObjectKeys = (obj: { [key: string]: any }) => {
    const list = [];
    for (let key in obj) {
        list.push([key, obj[key]]);
    }
    return list.sort((a, b) => {
        if (a[0] < b[0]) {
            return -1;
        } else if (b[0] > a[0]) {
            return 1;
        } else {
            return 0;
        }
    });
};

export const getRandomNumber = (min: number, max: number) => {
    return Math.random() * (max - min) + min;
};

export const exportAsPdfFile = (
    data: { [key: string]: string }[],
    title: string | string[],
    headers: { header: string; key: string; width: number }[],
    layout: Orientation = "landscape",
    image = null
) => {
    const config = new Config();
    config.autoSize = false;
    config.headerBackgroundColor = "#009bc0";
    config.headerTextColor = "#fff";
    config.fontSize = 14;

    const doc = new jsPDF({ orientation: layout });
    doc.text(title, layout === "landscape" ? 130 : 65, 10);
    const mainHeader = createHeaders(headers);

    if (image) {
        doc.addImage(image, "PNG", 70, 20, 70, 70);
        doc.table(10, 100, data, mainHeader, config);
    } else {
        // debugger;
        doc.table(10, 30, data, mainHeader, config);
    }
    doc.save(title + ".pdf");
};

const createHeaders = (keys: any[]) => {
    let result: CellConfig[] = [];
    for (let i = 0; i < keys.length; i++) {
        result.push({
            // header: keys[i].header,
            name: keys[i].key,
            prompt: keys[i].header,
            align: "center",
            width: keys[i].width?.toString(),
            padding: 0,
        });
    }
    return result;
};

export const checkForValue = (
    value: any,
    returnType: "string" | "number" = "string"
) => {
    let output = value;
    if (value === null || value === "" || !value || value === "null") {
        output = returnType === "number" ? 0 : "N/A";
        return output;
    }
    return output;
};

export const formatDate = (
    date: Date | string,
    dateFormat?: string
): string => {
    return moment(date).format(dateFormat ? dateFormat : "LL LT");
};

export const formatDataForExport = (
    request: { data: { [key: string]: any }; date: string | Date }[]
) => {
    let output: { [key: string]: any }[] = [];
    const sortedList = sortObjectKeys(request[0]?.data);
    sortedList.forEach((row, index) => {
        if (row[0] !== "date") {
            let pdfData: { [key: string]: any } = {
                serial: index + 1 + "",
                resource: titleCase(row[0]),
            };
            request.forEach((req, i) => {
                let dateString = formatDate(req?.date);
                // pdfData[dateString + ` (${index})`] = row[0];
                pdfData[dateString + ` (${i})`] =
                    typeof req?.data[row[0]] === "string"
                        ? titleCase(req?.data[row[0]])
                        : req?.data[row[0]].toString();
            });
            output.push(pdfData);
        }
    });
    return output;
};

export class Config implements TableConfig {
    autoSize: boolean = true;
    headerTextColor: string = "#fff";
    fontSize: number = 10;
    headerBackgroundColor: any;
    top: any;
}

export type Orientation = "landscape" | "portrait";

export const generatePreliminaryHeaders = (
    wholeData: { data: { [key: string]: any }; date: string | Date }[]
) => {
    const output: {
        header: string;
        key: string;
        width: number;
    }[] = [
        {
            header: "SN",
            key: "serial",
            width: 30,
        },
        {
            header: "Resources",
            key: "resource",
            width: 80,
        },
    ];
    wholeData.forEach((row, i) => {
        let day = formatDate(row?.date);
        output.push({
            header: titleCase(day),
            key: day + ` (${i})`,
            width: 65,
        });
    });
    return output;
};
