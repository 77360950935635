import { useCallback, useState } from "react";
import { useSnackbar } from "notistack";
import Axios from "axios";
import useReponseErr from "../reponseErr";
import { useDispatch, useSelector } from "react-redux";
import { setVisualizeData } from "../../store/sc";

function useVisualize() {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const { responseErr } = useReponseErr();
    const [data, setData] = useState("");
    const [visualizeReleaseData, setVisualizeReleaseData] = useState("");
    const token = useSelector((state) => state.project.bodyToken);

    function visualize(values) {
        setLoading(true);
        Axios.post("/visualize", { project_key: values, ...token })
            .then((res) => {
                setData(res.data);
                dispatch(setVisualizeData(res.data));
                enqueueSnackbar("Successful", { variant: "success" });
            })
            .catch((err) => {
                responseErr(err);
            });
    }

    const getVisualizeReleaseData = useCallback(
        (values) => {
            setLoading(true);
            Axios.post("/visualize/release", { ...values, ...token })
                .then((res) => {
                    setVisualizeReleaseData(res.data);
                    enqueueSnackbar("Successful", { variant: "success" });
                    setLoading(false);
                })
                .catch((err) => {
                    setLoading(false);
                    responseErr(err);
                });
        },
        [token, enqueueSnackbar, responseErr]
    );

    if (!loading) {
        closeSnackbar("key");
    } else {
        enqueueSnackbar("Getting visualization Data", {
            persist: true,
            variant: "default",
            key: "key",
        });
    }

    return {
        loading,
        data,
        visualizeReleaseData,
        visualize,
        getVisualizeReleaseData,
    };
}

export default useVisualize;
