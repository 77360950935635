const Button = ({ btnType = "yellow", btnName, onClick }) => {
    const yellowBtn = " text-white bg-[#E37915]";
    const whiteBtn = "text-[#595959] border border-[#d9d9d9]";
    return (
        <button
            className={`py-[10px] px-4 text-sm font-semibold rounded-lg ${
                btnType === "yellow" ? yellowBtn : whiteBtn
            }`}
            onClick={onClick}
            style={{
                boxShadow: "0px -2px 4px 0px #ffffff33",
            }}
        >
            {btnName}
        </button>
    );
};

export default Button;
