import React from "react";
// import styles from "./table.module.scss";

const Table = ({ data }) => {
    return (
        <>
            <div className="py-5 pl-6 font-medium text-[16px] leading-6 text-[#262626] dark:text-white border-b border-[#d9d9d9] dark:border-[#424E59]">
                Resources Comparison Table
            </div>

            <table style={{ margin: "0px", width: "100%" }}>
                <thead>
                    <tr className="font-medium text-[15px] leading-6 text-[#667085] bg-[#F9FAFB] dark:bg-[#424E59] dark:text-white text-left">
                        <th style={{ width: "5%" }} className="py-3 !pl-6">
                            #
                        </th>
                        <th style={{ width: "24%" }} className="!pl-6 py-3">
                            Resource
                        </th>
                        <th style={{ width: "24%" }} className="!pl-6 py-3">
                            Current Count
                        </th>
                        <th style={{ width: "24%" }} className="!pl-6 py-3">
                            Previous Count
                        </th>
                        <th style={{ width: "23%" }} className="!pl-6 py-3">
                            % Diff.
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((softwareCount) => (
                        <tr
                            className="text-[#595959] dark:text-white font-normal leading-6 text-[16px] border-b border-[#E5E7EB] dark:border-[#424E59] last:border-none "
                            key={softwareCount.title}
                        >
                            <td className="p-6">{+softwareCount.id + 1}</td>
                            <td className="p-6">{softwareCount.title}</td>
                            <td className="p-6">
                                {softwareCount.currentCount}
                            </td>
                            <td className="p-6">
                                {softwareCount.previousCount}
                            </td>
                            <td className="p-6">
                                {softwareCount.percentageDiff}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    );
};

export default Table;
