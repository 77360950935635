import React from "react";

const MigrateSideDrawer = () => {
    return (
        <>
            <div className="space-y-4 w-full">
                <div>
                    <div className="font-medium text-[#374151] dark:text-white text-[14px] leading-5">
                        Domain
                    </div>
                    <input
                        type="text"
                        name="domain"
                        className="w-full py-[10px] pl-[14px] rounded-lg placeholder:text-[#98A2B3] placeholder:text-[16px] placeholder:leading-6 border border-[#D9D9D9]"
                        placeholder="app-test-1"
                    />
                </div>
                <div>
                    <div className="font-medium text-[#374151] dark:text-white text-[14px] leading-5">
                        Email Address
                    </div>
                    <input
                        type="email"
                        name="email"
                        className="w-full py-[10px] pl-[14px] rounded-lg placeholder:text-[#98A2B3] placeholder:text-[16px] placeholder:leading-6 border border-[#D9D9D9]"
                        placeholder="example@email.com"
                    />
                </div>
                <div>
                    <div className="font-medium text-[#374151] dark:text-white text-[14px] leading-5">
                        Password
                    </div>
                    <input
                        type="password"
                        name="password"
                        className="w-full py-[10px] pl-[14px] rounded-lg placeholder:text-[#98A2B3] placeholder:text-[16px] placeholder:leading-6 border border-[#D9D9D9]"
                    />
                </div>
            </div>

            <p className="text-[14px] text-[#595959] dark:text-white leading-5 mt-2">
                To obtain your API Key, navigate to Profile, select Manage
                Account, and generate it from there.
            </p>
        </>
    );
};

export default MigrateSideDrawer;
