import ProjectContainer from "components/common/ProjectContainer";
import React from "react";

const ExportSideDrawer = () => {
    return (
        <>
            <ProjectContainer />
        </>
    );
};

export default ExportSideDrawer;
