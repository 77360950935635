import React from "react";

const EmptyPiechart = () => {
    return (
        <>
            <svg
                width="318"
                height="318"
                viewBox="0 0 318 318"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M4.72817 197.486C-4.24418 161.52 -0.368252 123.549 15.6859 90.1375C31.7401 56.7261 58.9635 29.9738 92.6501 14.5054L119.19 72.3032C98.9782 81.5843 82.6441 97.6356 73.0116 117.682C63.3791 137.729 61.0536 160.512 66.437 182.091L4.72817 197.486Z"
                    fill="#F0F0F0"
                />
                <path
                    d="M278.019 264.431C259.16 285.721 234.935 301.562 207.869 310.304C180.804 319.046 151.89 320.367 124.14 314.131C96.3899 307.895 70.8207 294.33 50.0976 274.85C29.3744 255.369 14.2561 230.686 6.31836 203.375L67.3913 185.625C72.1539 202.012 81.225 216.821 93.6589 228.51C106.093 240.198 121.434 248.337 138.084 252.079C154.734 255.82 172.083 255.027 188.322 249.782C204.561 244.537 219.096 235.033 230.412 222.259L278.019 264.431Z"
                    fill="#F0F0F0"
                />
                <path
                    d="M190.536 3.15886C225.777 10.2902 257.56 29.1551 280.699 56.675C303.838 84.1949 316.966 118.745 317.941 154.686L254.365 156.412C253.78 134.847 245.903 114.117 232.019 97.605C218.136 81.0931 199.066 69.7741 177.922 65.4953L190.536 3.15886Z"
                    fill="#1F78B4"
                />
                <path
                    d="M190.536 3.15894C225.777 10.2903 257.56 29.1552 280.699 56.6751C303.838 84.195 316.966 118.745 317.942 154.686L254.365 156.412C253.78 134.847 245.903 114.117 232.019 97.6051C218.136 81.0931 199.066 69.7742 177.922 65.4954L190.536 3.15894Z"
                    fill="#F0F0F0"
                />
                <path
                    d="M97.7363 12.2767C125.917 0.509989 156.89 -2.89233 186.953 2.47646L175.772 65.0859C157.734 61.8646 139.15 63.906 122.242 70.966L97.7363 12.2767Z"
                    fill="#F0F0F0"
                />
                <path
                    d="M318.001 159C318.001 196.814 304.524 233.39 279.989 262.164L231.594 220.898C246.315 203.634 254.401 181.688 254.401 159H318.001Z"
                    fill="#F0F0F0"
                />
                <rect
                    x="99"
                    y="122"
                    width="120"
                    height="46"
                    rx="3"
                    fill="#F5F5F5"
                />
                <rect
                    x="99"
                    y="172"
                    width="120"
                    height="24"
                    rx="3"
                    fill="#F5F5F5"
                />
            </svg>
        </>
    );
};

export default EmptyPiechart;
